import React, { useEffect, useState} from 'react'
import './Filters.css'
import Select from 'react-select'
// import data from '../../data/trains.json'
import api from '../../api/api'

const DashboardFilters = ({onSubmit}) => {
    const [date, setdate] = useState();
    const [selecttrainNumber, setSelectTrainNumber] = useState('')
    const [autoTrainName, setAutoTrainName] = useState('train_name')
    // const [trainData, setTraindata] = useState({})
    const [TrainNumbers, setTrainNumbers] = useState([{ value: '', label: '' }])

    const TrainNumberChangeHandler = (e) => {
        setSelectTrainNumber(e.value);
        getTrainName(e.value.toString())
    };

    const dateChangeHandler = (e) => {
        setdate(e.target.value);
    };

    const formatDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = yyyy + "-" + mm + "-" + dd;
        setdate(formattedToday);
    };

    const handleSubmit = () => {
        console.log(selecttrainNumber, date)
        onSubmit(selecttrainNumber,date)
    }

    const getTrainName = (train_no) => {
        api.get(`/api/get_train_name/?train_no=${train_no}`
            ,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            console.log(response.data)
            setAutoTrainName(response.data.train_name)
        })
    }

    useEffect(() => {
        formatDate()

        var trains_numbers = []
        var train_data = {}
        // data.data.map((train) => {
        //     trains_numbers.push({
        //         value: train['train_number'],
        //         label: train['train_number']
        //     })
        //     train_data[train['train_number'].toString()] = train['train_name']
        // })

        const trains = JSON.parse(localStorage.getItem('trains'))
        trains.map((train) => {
            trains_numbers.push({
                value: train,
                label: train
            })
            // train_data[train.toString()] = train['train_name']
        })

        setTrainNumbers(trains_numbers)
        // setTraindata(train_data)

    },[])


    return(
        <div className='filter-component'>
            <div className='filter-wrapper '>
                <Select 
                    className='min-w-64 mt-2 rounded' 
                    options={TrainNumbers} 
                    placeholder='Select Train Number' 
                    onChange={(e) => TrainNumberChangeHandler(e)} 
                    value={selecttrainNumber ? { value: selecttrainNumber, label: selecttrainNumber } : null}
                />
                <input 
                    className='dateFilter min-w-64 mt-2 rounded' 
                    type='text' placeholder='train_name' 
                    value={autoTrainName} readOnly 
                />
                <input 
                    className='dateFilter min-w-64 mt-2 rounded' 
                    type='date' value={date} 
                    onChange={(e) => dateChangeHandler(e)} 
                />
            </div>
            <div>
                <button 
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6' 
                    onClick={() => handleSubmit()}
                >
                    Submit
                </button>
            </div>
        </div>
    )
}

export default DashboardFilters;