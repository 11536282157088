const Permissions = {
    admin: ["s2 admin", "railway admin"],
    write: ["s2 admin", "railway admin", "write read"],
    read: ["s2 admin", "railway admin", "write read", "EHK", "coach attendent", "railway officer"]
  };
  
const getWritePermissions = () => {
    return Permissions.write;
};
  
const getReadPermissions = () => {
    return Permissions.read;
};

const getadminPermissions = () => {
    return Permissions.admin;
};
  
module.exports = {
    getadminPermissions,
    getWritePermissions,
    getReadPermissions
};