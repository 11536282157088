import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  CModal,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CButton,
} from '@coreui/react';
import GoogleAuth from '../../components/GoogleAuth';
import './Login.css'
import '@coreui/coreui/dist/css/coreui.css';
import '@coreui/icons/css/all.css';
import BackgroundPattern from '../../components/BackgroundPattern/BackgroundPattern';
import api from '../../api/api';
import Loader from '../../components/Loader/Loader';
import { FetchTrains } from '../../utils/FetchTrains';

const Login = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState();
  const [showIcon, setShowIcon] = useState(true);
  const [visible, setVisible] = useState();
  const [messageColor, setMessageColor] = useState();
  const [password, setPassword] = useState();
  const [phone, setPhone] = useState();
  const [passwordType, setPasswordType] = useState('password');
  const [showLoader, setShowLoader] = useState(false);

  const handleErrorChange = (message) => {
    setVisible(true);
    setMessage(message);
    setMessageColor('red');
  };

  const submitHandler = (e) => {
    setShowLoader(true)
    e.preventDefault();
    if (!phone || !password) {
      setVisible(true);
      setMessage('Please enter both mobile number and password.');
      setMessageColor('red');
      setShowLoader(false)
      return;
    }
    api
      .post(
        `api/users/login/`,
        {
          phone: phone,
          password: password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': '{{ csrf_token }}',
          },
        }
      )
      .then((response) => {
        console.log(response.data.access_token)
        FetchTrains()
        localStorage.setItem('userData', JSON.stringify(response.data));
        if (response.data.access_token) {
          document.cookie = `token=${response.data.access_token}; path=/`;
          navigate('/TrainDetails', { replace: true });
        } else {
          setVisible(true);
          setMessage(response.data.message);
          setMessageColor('red');
        }
      })
      .catch((error) => {
        setVisible(true);
        var message = '';
        // eslint-disable-next-line array-callback-return
        Object.keys(error.response.data).map(function (key) {
          message = message + ' ' + error.response.data[key];
        });
        setMessage(message);
        setMessageColor('red');
      }).finally(()=>{
        setShowLoader(false);
      })
  };
  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      setShowIcon(false);
      return;
    }
    setPasswordType('password');
    setShowIcon(true);
  };

  useEffect(() => {
    if (localStorage.getItem('userData')) {
      navigate('/TrainDetails', { replace: true });
    }
  }, [navigate]);

  return (
    <div>
      <div className="loader">
        {
          showLoader && <Loader></Loader>
        }
      </div>
      <center>
        <BackgroundPattern />
        <div className="Login-Wrapper">
          <CModal
            visible={visible}
            onClose={() => setVisible(false)}
            aria-labelledby="LiveDemoExampleLabel"
          >
            <CModalHeader onClose={() => setVisible(false)}>
              <CModalTitle id="LiveDemoExampleLabel">Alert</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <p style={{ color: messageColor }}>{message}</p>
            </CModalBody>
            <CModalFooter>
              <CButton color="secondary" onClick={() => setVisible(false)}>
                Close
              </CButton>
            </CModalFooter>
          </CModal>
          <div className="login-card" >
            <div>
              <form method="post" className='border' onSubmit={submitHandler}>
              <div className="form-input">
                <label htmlFor="phone">Mobile Number</label>
                <div className="input-field">
                  <span class="material-symbols-outlined">
                    dialpad
                  </span>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    className='form-control rounded'
                    placeholder='Enter Mobile Number'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-input">
                <label htmlFor="password">Password</label>
                <div className="input-field">
                  {showIcon ? <span class="material-symbols-outlined">
                    lock
                  </span> : <span class="material-symbols-outlined">
                    lock_open
                  </span>
                  }
                  
                  <input
                    type={passwordType}
                    name="password"
                    id="password"
                    className='form-control rounded'
                    placeholder='Enter Your Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="password-container">
                <div className="showpassword">
                  <input
                    className="h-3 w-3 mt-0.5 checkbox"
                    type="checkbox"
                    onClick={togglePassword}
                  />

                  <span className="pl-1 max-sm:pl-0.5">Show Password</span>
                </div>
                <div>
                  <small>
                    <Link
                      className="forgot-password no-underline hover:underline hover:text-red-500"
                      to="/ForgotPassword"
                    >
                      Forgot Password?
                    </Link>
                  </small>
                </div>
              </div>

              <div className="form-input" >
                <button type="submit" className="login-button">
                  Log In
                </button>
              </div>
              
            </form>
            </div>
          </div>
          <div className='login-card secodaryLogIn '>
          <div className="end-text">
                <center className="my-3">
                  <br />
                  <GoogleAuth handleErrorChange={handleErrorChange} />
                  <br />
                  <p className="end-text">
                    <Link to="/MobileLogIn">
                      <span className="loginSms">
                        <span class="material-symbols-outlined">
                          phone_iphone
                        </span> Login using OTP on SMS
                      </span>
                    </Link>
                  </p>
                  <div>
                    <p>Don't have an account?</p>
                  <h4 className=''>
                    Request for a{' '}
                    <Link to="/signup">
                      <span className="hover:text-blue-500 hover:underline">
                        SignUp
                      </span>
                    </Link>{' '}
                    now
                  </h4>
                  </div>
                </center>
              </div> 
               <div className=" justify-center items-center">
        <span className="text-center w-full justify-center items-center flex flex-row space-x-2 text-sm">
          <span>Developed By:</span>
          <a className="text-blue-500 text-[18px] no-underline" href='https://suvidhaen.com/'> Sarva Suvidhaen</a>
        </span>
        <span className="w-full flex flex-row justify-between md:justify-evenly items-center text-center px-8 pt-2">
          <a className="no-underline text-gray-600 text-base" href="https://suvidhaen.com/privacypolicy" target="_blank" rel="noopener noreferrer">
            privacy policy
          </a>
          <a className="no-underline text-gray-600 text-base" href="https://suvidhaen.com/termcondition" target="_blank" rel="noopener noreferrer">
            terms of conditions
          </a>
        </span>
      </div>
          </div>
        </div>
      </center>
    
    </div>
  );
};

export default Login;