import React, { useEffect, useRef } from 'react';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'jszip';
import $ from 'jquery';
import DataTable from 'datatables.net';
import './Table.css'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import api from '../../api/api';

const DashboardTable = ({data, downloadPdf, mailPdf}) => {
    const tableRef = useRef(null);
    const dataTable = useRef(null);

    useEffect(() => {
        if (data.length === 0) {
            if (dataTable.current) {
                dataTable.current.clear().draw();
            }
            return;
        }

        if (!dataTable.current) {
            dataTable.current = new DataTable(tableRef.current, {
                dom: 'Bfrtip',
                paging: false,
                buttons: []
            });
        }

        dataTable.current.clear();

        data.forEach((row, index) => {
            dataTable.current.row.add([
                index + 1,
                row.coach_numbers.join(', '),
                `${row.user_type}: ${row.username}`,
                row.phone_number
            ]);
        });

        dataTable.current.draw();
    }, [data]);

    console.log(data)
    return(
        <div>
            <div className="table-wrapper mt-4" hidden={data.length == 0}>
                <button className='btn btn-primary' onClick={() => {downloadPdf()}}>Download pdf</button>
                <button className='btn btn-primary ml-4' onClick={() => {mailPdf()}}>Mail pdf</button>
                <table ref={tableRef} id="dashboard-table" >
                    <thead>
                        <tr>
                            <th className=''>Sno</th>
                            <th className=''>Coach nos</th>
                            <th className=''>User</th>
                            <th className=''>phone number</th>
                        </tr>
                    </thead>
                </table>
            </div>
            {data.length == 0 && <h6 className='mt-4'>No User is assigned to this Train</h6>}
        </div>
    )
}

export default DashboardTable;