import React, { useCallback, useEffect, useRef } from 'react';

import api from "../api/api";
import { useNavigate } from "react-router-dom";

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

const GoogleAuth = ({ handleErrorChange }) => {
  const googleButton = useRef(null);
  const navigate = useNavigate();

  function androidSignin() {
    // eslint-disable-next-line no-undef
    var text = AndroidInterface.getAuthToken();
    if (!text) {
      handleErrorChange("Google signin failed");
      return;
    }
    var data = { auth_token: text };
    api
      .post(`api/users/google/`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": "{{ csrf_token }}",
        },
      })
      .then((response) => {
        localStorage.setItem("userData", JSON.stringify(response.data));
        if (response.data.access_token) {
          document.cookie = `token=${response.data.access_token}; path=/`;
          localStorage.setItem("username", response.data.username);
          navigate("/TrainDetails", { replace: true });
        } else {
          handleErrorChange(response.data.message);
        }
      })
      .catch((error) => {
        if(error.response.data.message){
          handleErrorChange(error.response.data.message);
        }
        else{
          handleErrorChange("Failed To Log In");
        }
        
      });
  }
  useEffect(() => {
    const src = "https://accounts.google.com/gsi/client";
    const id =
      "675259227325-388l4epputn2sbg0mknceucdq3ram2dk.apps.googleusercontent.com";

    loadScript(src)
      .then(() => {
        /*global google*/

        google.accounts.id.initialize({
          client_id: id,
          callback: handleCredentialResponse,
        });
        google.accounts.id.renderButton(googleButton.current, {
          theme: "outline",
          size: "large",
        });
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, []);

  const handleCredentialResponse = useCallback(
    (response) => {
      if (response.credential) {
        var data = { auth_token: response.credential };
        api
          .post(`api/users/google/`, JSON.stringify(data), {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": "{{ csrf_token }}",
            },
          })
          .then((response) => {
            localStorage.setItem("userData", JSON.stringify(response.data));
            if (response.data.access_token) {
              document.cookie = `token=${response.data.access_token}; path=/`;
              localStorage.setItem("username", response.data.username);
              navigate("/TrainDetails", { replace: true });
            } 
          })
          .catch((error) => {
            if(error.response.data.message){
              handleErrorChange(error.response.data.message);
            }
            else{
              handleErrorChange("Failed To Log In");
            }
          });
      }
    },
    [handleErrorChange, navigate]
  );

  return (
    <div id="google-login-btn">
      <div ref={googleButton} id="google-ref">
        {/* Loading */}
        <a href="#" onClick={androidSignin}>
          <img
            src={''}
            alt="Signin with Google"
            style={{ width: "50vw", height: "7vh" }}
          />
        </a>
      </div>
    </div>
  );
};

export default GoogleAuth;
