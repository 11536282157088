import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AllRoutes from './AllRoutes';
import React, { useEffect } from 'react';
import {scheduleApiCalls} from './utils/FetchLocation'

function App() {

  useEffect(() => {
    const cleanup = scheduleApiCalls();
    return cleanup; 
  }, []);

  return (
    <div className='App'>
      <BrowserRouter>
        <AllRoutes/>
      </BrowserRouter>
    </div>
  );
}

export default App;
