import React, { useEffect, useRef, useState } from 'react';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import Popup from 'reactjs-popup';
import 'jszip';
import $ from 'jquery';
import DataTable from 'datatables.net';
import station_data from '../../data/stations.json'

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TotalTable = ({ details, trainTimingInfo }) => {
    const totaltableRef = useRef(null);
    const dataTable = useRef(null);
    const [totalCount,setTotalCount]=useState(0);
    const [date, setDate] = useState()
    const [loadedDate, setLoadedDate] = useState("")
    const [updatedDate, setupdatedDate] = useState("")
    const [chartExpectedTime, setchartExpectedTime] = useState("")

    const dateConverter = (date) => {
        if (date === undefined) return '';
        let newDate = new Date(date);
        let day = newDate.getDate();
        let month = newDate.getMonth() + 1; // Note: January is 0
        let year = newDate.getFullYear();
        let hours = newDate.getHours();
        let minutes = newDate.getMinutes();
        let seconds = newDate.getSeconds();
        let milliseconds = newDate.getMilliseconds();
        let dayString = day < 10 ? '0' + day : day.toString();
        let monthString = month < 10 ? '0' + month : month.toString();
        let yearString = year.toString();
        let hourString = hours < 10 ? '0' + hours : hours.toString();
        let minuteString = minutes < 10 ? '0' + minutes : minutes.toString();
        let secondString = seconds < 10 ? '0' + seconds : seconds.toString();
        let millisecondString = milliseconds < 100 ? milliseconds < 10 ? '00' + milliseconds : '0' + milliseconds : milliseconds.toString();
        let formattedDateTime = dayString + '-' + monthString + '-' + yearString + ' ' + hourString + ':' + minuteString + ':' + secondString + '.' + millisecondString;
        return formattedDateTime;
    };

    const formatDate = (date) => {
        if(date){
            let newDate = new Date(date);
            let day = newDate.getDate();
            let month = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            let dayString = day < 10 ? '0' + day : day.toString();
            let monthString = month < 10 ? '0' + month : month.toString();
            let yearString = year.toString();

            return dayString + "-" + monthString + "-" + yearString
        }
    } 
    

    
    useEffect(() => {
        /* (details.details.length === 0) {
            if (dataTable.current) {
                dataTable.current.clear().draw();
            }
            return;
        }*/

        if (!dataTable.current) {
            dataTable.current = new DataTable(totaltableRef.current, {
                dom: 'Bfrtip',
                paging: false,
                buttons: []

            });
        }
        
        dataTable.current.clear();

        // dataTable.current.row.add([
        //     0,
        //     details.train_number,
        //     details.station_code,
        //     details.date
        // ])
        let allBirthInTrain = 0;
        details.forEach((details, index) => {

           /* const newRow = dataTable.current.row.add([
                0,
                details.train_number,
                details.station_code,
                details.date
            ]).draw().node();*/
           // $(newRow).addClass('hidden-row');
            let tempTotalBirth = 0;
            let tempTotalBirthOffboarding = 0
            let stationName = details.station_code;

            details.details.forEach((row, index) => {
                tempTotalBirth = tempTotalBirth + row.count_of_birth;
                tempTotalBirthOffboarding = tempTotalBirthOffboarding + row.off_boarding_count_of_birth;
            });
            dataTable.current.row.add([
                index + 1,
                stationName,
                tempTotalBirth,
                tempTotalBirthOffboarding
            ]);
            allBirthInTrain = allBirthInTrain + tempTotalBirth;
            
        });
        setTotalCount(allBirthInTrain);
        dataTable.current.draw();
    }, [details]);

    useEffect(() => {

        setDate(details[0].date)
        
        if (details.length === 0 && dataTable.current) {
            dataTable.current.destroy();
            dataTable.current = null;
        }

        let loaded_date; 
        let updated_date;
        let chart_expectedTime;

        if(details[0].loaded_at_recent!==""){
            updated_date=`Charting refreshed at: ${dateConverter(details[details.length-1].loaded_at_recent)}`
            loaded_date=`Charting started at: ${dateConverter(details[0].loaded_at_earliest)}`;
        }
        else{
            updated_date=""
            loaded_date="Data was not loaded For this train."
        }
    
        if(trainTimingInfo.charting_time === null){
            chart_expectedTime = "Not Mentioned"
        }else{
            let date_obj = new Date(details[0].date)
            if(trainTimingInfo.charting_day === 'today'){
                let today_date = formatDate(date_obj)
                chart_expectedTime =  today_date + " " + trainTimingInfo.charting_time
            }else{
                date_obj.setDate(date_obj.getDate() - 1)
                let yesterday_date = formatDate(date_obj)
                chart_expectedTime =  yesterday_date + " " + trainTimingInfo.charting_time
            }
        }

        setLoadedDate(loaded_date)
        setupdatedDate(updated_date)
        setchartExpectedTime(chart_expectedTime)
    }, [details]);

    return (
        <div>
            
            <div className="table-wrapper" >
                <div className='m-1 info-train-date'>
                <Popup
                    className="popup-section"
                    trigger={
                        <span class="material-symbols-outlined float-left p-2">
                            info
                        </span>
                 
                    }
                    position = {
                        window.innerWidth < 500 ? 'top left' : 'right'
                    }
                    
                    closeOnDocumentClick
                >
                    <div className='my-1 p-2 shadow rounded border bg-white'>
                    <p className='p-0 m-0'>{loadedDate}</p>
                    <p className='p-0 m-0'>{updatedDate}</p>
                    <p className='p-0 m-0'>Train Scheduled at: {formatDate(date)} {trainTimingInfo.start_time} </p>
                    <p className='p-0 m-0'>Expected Charting Time: {chartExpectedTime}</p>
                    </div>
                </Popup>
                
                </div>
                <table ref={totaltableRef} id={`totalcountTable`} className='my-2 train-details-1'>
                    <thead>
                        <tr>
                            <th className='custom-class-column-1'>Sno</th>
                            <th className='custom-class-column-2'>Station Code</th>
                            <th className='custom-class-column-3'>Count Of Total Berths Onboarding</th>
                            <th className='custom-class-column-3'>Count Of Total Berths Offboarding</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <td colspan="4">Total Count For Train:  <strong>{totalCount}</strong></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
}

export default TotalTable;