import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from "react";
import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import Navbar from '../../components/Navbar/Navbar';
import Loader from '../../components/Loader/Loader';

const PasswordChangeOtp = () => {
    const [displaySidebar, setDisplaySidebar] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState('');
    const [otp, setOtp] = useState("");
    const [verifyMsg, setVerifyMsg] = useState('');
    const [msgclr, setMsgclr] = useState('white');
    const navigate = useNavigate();
    const toggleSideBar = () => {
        setDisplaySidebar(!displaySidebar);
    };
    const validateOtp = () => {
        if (otp !== "") {
            if (otp.length === 6) {
                return true;
            }
            else {
                setShowErrorMsg("OTP length should be 6");
                setShowModal(true)
                return false;
            }
        }
        else {
            setShowErrorMsg("OTP length should be Blank");
            setShowModal(true)
            return false;
        }
    }
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 991) {
                setDisplaySidebar(false);
            } else {
                setDisplaySidebar(true);
            }
        };
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const emailotpSubmit = (e) => {
        setShowLoader(true)
        e.preventDefault()
        setVerifyMsg("Otp is verifying")
        setMsgclr('#ccf1fd');
        api.post('api/users/profile/edit-profile/change-email/enter-otp/', {
            'otp': otp,
            'email': localStorage.getItem('new_email_otp')
        }).then(response => {
            console.log(response.data)
            if (response.data.message != null) {
                localStorage.removeItem('new_email_otp')
                setShowErrorMsg(response.data.message)
                setShowModal(true);
                setTimeout(() => {
                    navigate("/EditProfile");
                }, 2000);
            }
        }).catch((error) => {
            // setShowErrorMsg(error.response.data.message)
            // setShowModal(true)
            if (error.response) {
                setVerifyMsg(error.response.data.message);
                setMsgclr('#ccf1fd');
            }
            else {
                setVerifyMsg(error.message)
                setMsgclr('#ccf1fd');
            }
        }).finally(()=>{
            setShowLoader(false)
        })
    }
    const phoneotpSubmit = (e) => {
        e.preventDefault()
        setShowLoader(true);
        setVerifyMsg("Otp is verifying")
        setMsgclr('#ccf1fd');
        if (otp === '') {
            setShowErrorMsg("Mobile number already exist");
            setShowModal(true);
            return;
        }
        api.post('api/users/profile/edit-profile/change-phone/conf-otp/', {
            'otp': otp,
            'phone': localStorage.getItem('new_phone_otp')
        }).then(response => {
            localStorage.removeItem('new_phone_otp')
            if (response.data.message === "Mobile Number updated successfully.") {
                setShowErrorMsg(response.data.message)
                setShowModal(true);
            }
            setTimeout(() => {
                navigate("/EditProfile");
            }, 2000);
            
        }).catch(error => {
            if (error.response) {
                setVerifyMsg(error.response.data.message);
                setMsgclr('#ccf1fd');
            }
            else {
                setVerifyMsg(error.message)
                setMsgclr('#ccf1fd');
            }

        }).finally(() => {
            setShowLoader(false);
        });
    }
    const otpSubmit = (e) => {
        setShowLoader(true);
        e.preventDefault()
        if (validateOtp()) {
            setVerifyMsg("Otp is verifying")
            setMsgclr('#ccf1fd');
            api.post('api/users/profile/edit-profile/change-password/enter-otp/', {
                'otp': otp,
            }).then(response => {
                console.log(response.data)
                if (response.data.message != null) {
                    setShowErrorMsg(response.data.message);
                    setShowModal(true);
                    setTimeout(() => {
                        navigate('/EditProfile');
                    }, 3000);
                }

            }).catch((error) => {
                console.log(error);
                if (error.response) {
                    setVerifyMsg(error.response.data.message);
                    setMsgclr('#ccf1fd');
                }
                else {
                    setVerifyMsg(error.message)
                    setMsgclr('#ccf1fd');
                }
            }).finally(()=>{
                setShowLoader(false);
            })
        }
        else {
            console.log("error");
            setShowLoader(false);
        }
    }

    return (
        <div className='page-body'>
            <div className="loader">
                {
                  showLoader &&  <Loader></Loader>
                }
            </div>
            <div>
                <CModal
                    visible={showModal}
                    backdrop="static"
                    aria-labelledby="ScoreNow"
                >
                    <CModalBody>
                        <h5>{showErrorMsg}</h5>
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color="secondary"
                            onClick={() => {
                                setShowModal(false);
                            }}
                        >
                            Ok
                        </CButton>
                    </CModalFooter>
                </CModal>
            </div>
            <div>
                <Navbar toggleSideBar={toggleSideBar} />
            </div>
            <div style={{ marginLeft: displaySidebar === true ? "230px" : "0px" }}></div>
            <section className="container-fluid mx-auto enter_otp" style={{ backgroundColor: "transparent", marginTop: "120px" }}>
                <div className="container col-md-4">
                    <div className=" mb-4">
                        <div className="card-body">
                            <center>
                                <h3 style={{ color: "blue" }}>Enter OTP</h3>
                            </center>
                            <form action="" method="post" className='shadow w-100'>
                                <div className="form-group">
                                    <label htmlFor="otp" className="form-label" style={{ fontSize: "14px" }}>OTP Code</label>
                                    <input type="number" className="form-control rounded" placeholder='Enter OTP Here' name="otp" id="otp" onChange={(e) => setOtp(e.target.value)} />
                                </div>
                                <div className="row text-center justify-content-center">
                                    <div className="text-center">
                                        <button className="btn btn-success w-100 col-md-4 my-3 edit-submit-btns" type="submit"
                                            onClick={
                                                localStorage.getItem("new_email_otp") ? emailotpSubmit :
                                                    localStorage.getItem("new_phone_otp") ? phoneotpSubmit :
                                                        otpSubmit
                                            }
                                        >
                                            Submit OTP
                                        </button>

                                        <div className="verify-msg" style={{ backgroundColor: msgclr }}><center>{verifyMsg}</center></div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <span id="otpTimer" className="w-100"></span>
                                        <button id="resendOtpBtn" className="btn btn-success w-100" type="button" style={{ display: 'none' }}>Resend OTP</button>
                                    </div>
                                </div>
                                <div className="text-center">
                                    {/* Additional text/content */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PasswordChangeOtp;