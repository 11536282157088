import React, { useEffect, useState, useCallback } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import Loader from '../../components/Loader/Loader';
import MapComponent from '../../components/Map/MapComponent.js'
import api from '../../api/api.js';
import useGeoLocation from '../../hooks/useGeoLocation.js';
import { useLocation } from 'react-router-dom';
import PopupMap from '../../components/Popup/PopupMap.js';


const MapScreen = () => {
    const [displaySidebar, setDisplaySidebar] = useState(false)
    const [showLoader, setShowLoader] = useState(true);
    const [stations, setStations] = useState()
    const location = useGeoLocation()
    const [trainNo, setTrainNo] = useState()
    const [trainRoute, setTrainRoute] = useState()
    const [date, setDate] = useState()
    const [showPopup, setShowPopup] = useState(false);

    const locState = useLocation()

    const toggleSideBar = () => {
        setDisplaySidebar(!displaySidebar)
    }

    useEffect(() => {
        let train_no_from_location
        let date_from_location

        if(locState.state){
            train_no_from_location = locState.state.train_no
            date_from_location = locState.state.date
        } else {
            setShowPopup(true);
            setShowLoader(false)
        }

        setTrainNo(train_no_from_location)
        setDate(date_from_location)

        fetchRailwayStation()
        fetchTrainRoute(train_no_from_location)

        // sendMail(train_no_from_location, date_from_location)
        const handleResize = () => {
            if (window.innerWidth < 991) {
                setDisplaySidebar(false);
            } else {
                setDisplaySidebar(true);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    },[location.loaded, locState]);

    const fetchRailwayStation = () => {
        if(location.loaded){
            let lat = location.coordinates.lat
            let lng = location.coordinates.lng


            console.log(location.coordinates)
            api.get(
                `api/overpass/?lat=${lat}&lng=${lng}`
            ).then((response) => {
                
                let stations_all = response.data.elements
                const filteredData = stations_all.filter(item => item.tags.ref !== undefined);

                setStations(filteredData)
            })
        }
    }

    const fetchTrainRoute = (train_no) => {
        if(train_no){
            api.get(
                `api/train-route/?train_no=${train_no}`
            ).then((response) => {
                setTrainRoute(response.data.train_route)
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    const sendMail = (train_no, date) => {
        if(location.loaded && train_no && date){
            let lat = location.coordinates.lat
            let lng = location.coordinates.lng

            api.post(
                `mail/onboarding_details/`, {
                    // "date": date,
                    // "train_no": train_no,
                    "lat": lat,
                    "lng": lng
                },{
                    headers: {
                      'Content-Type': 'application/json',
                      'X-CSRFToken': '{{ csrf_token }}',
                    },
                  }
            ).then((response) => {
                console.log(response.data.message)
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    return (
        <div>
            <Navbar toggleSideBar={toggleSideBar} />
            <div className='page-body' style={{ marginLeft: displaySidebar ? '230px' : 'auto' }}>
                <center>
                    {stations && trainRoute ? <MapComponent stations={stations } trainRoute = {trainRoute} trainNo={trainNo} date={date} /> :  showLoader ? <Loader></Loader>:<></>}
                </center>
            </div>
            {showPopup && <PopupMap flag={showPopup} setFlag={setShowPopup} />}
        </div>
    )
}

export default MapScreen