import api from "../api/api";

export const FetchTrains = () => {
    const now = new Date().toISOString();
    const today = now.split('T')[0];
    api.get(
        '/api/all-trains',
        {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': '{{ csrf_token }}',
            },
        }
    ).then((response) => {
        console.log(response.data)
        localStorage.setItem('trains', JSON.stringify(response.data))
        localStorage.setItem('lastTrainsFetched',today )
    })
}