import React from 'react'
import './Navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../Sidebar/Sidebar';
import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

const Navbar = (props) => {

  const [displaySidebar, setDisplaySidebar] = useState(false)

  const toggleSidebar = () => {
    if (window.innerWidth > 991) {
      props.toggleSideBar()
    }
    setDisplaySidebar(!displaySidebar)
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 991) {
        setDisplaySidebar(false);
      } else {
        setDisplaySidebar(true);
      }
    };

    handleResize();

    // window.addEventListener('resize', handleResize);

    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, []);
  
  library.add(faBars)
  return (
    <div>
      <div className='Navbar'>
        <FontAwesomeIcon icon="fa-solid fa-bars" className='text-white text-xl mt-3 ml-6' onClick={() => {toggleSidebar()}} />
        <Link to='/TrainDetails'><h3 className='text-white font-bold text-xl float-left mt-2 ml-4'>Sarva Suvidhaen</h3></Link>
      </div>
      {displaySidebar && <Sidebar/> }
    </div>
  )
}

export default Navbar