import React from "react";
import {
    CModal,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CButton,
} from '@coreui/react';

const DeactivatePopup = ({flag,setFlag,deactivateAccount}) => {
    return(
        <div>
            <CModal
                visible={flag}
                onClose={() => {setFlag(false)}}
            >
                <CModalHeader
                    onClose={() => {setFlag(false)}}
                >
                    <CModalTitle id="LiveDemoExampleLabel">Deactivate account</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div>
                        <h5 className={'red-500'} >Do you really want to deactivate your account?..... Please press OK to confirm.'</h5>
                    </div>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="primary"
                        onClick={() => {deactivateAccount()}}
                    >
                        Ok
                    </CButton>
                </CModalFooter>
            </CModal>
        </div>
    )
}

export default DeactivatePopup