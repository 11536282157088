import React, {useState, useEffect} from "react";
import Switch from "react-switch";
import Select from 'react-select';
import api from "../../api/api";

const ToggleInsideTrain = ({addedtrains,setMsgModalFlag,setMessage,setModalType}) => {
    const [checked, setChecked] = useState(false)
    const [insideTrain, setInsideTrain] = useState("")
    const [retTrain, setRetTrain] = useState("")
    const [addedTrainsDict, setAddedTrainsDict] = useState({})
    const handleChecked = () => {
        setChecked(!checked)
    }
    const fetchDetails = () => {
        api.get(
            '/api/users/inside_train_status/'
        ).then((response) => {
            setChecked(response.data.inside_train)
            setRetTrain(response.data.inside_train_number)
        })
    }

    const handleToggleStatus = () => {
        let inside_train = false
        if(checked){
            inside_train = "true"
        }
        api.post(
            '/api/users/toggle_inside_train/',
            {
                inside_train: inside_train,
                inside_train_number : insideTrain
            }
        ).then((response) => {
            console.log(response.data)
            setMessage("Updated successfully")
            setModalType("success")
            setMsgModalFlag(true)
            fetchDetails()
        }).catch((error) => {
            setMessage("couldn't update")
            setModalType("error")
            setMsgModalFlag(true)
        })
    }

    useEffect(() => {
        fetchDetails()
        var trains_numbers = []
        addedtrains.map((train) => {
            trains_numbers.push({
                value: train,
                label: train
            })
        })
        setAddedTrainsDict(trains_numbers)

    },[addedtrains])
    return(
        <div className='mt-10 p-3'>
            <h5>Inside Train: <span className="ml-4 mt-2"><Switch checked={checked} onChange={handleChecked} offColor="#f00" height={20} width={40} /></span></h5>
            <h5>Train number: {retTrain}</h5>
            <Select 
              className='min-w-64 mt-2 rounded'  
              placeholder='Select Train Number' 
              options={addedTrainsDict}
              onChange={(e) => setInsideTrain(e.value)}
            />
            <button className="btn btn-primary mt-4" onClick={handleToggleStatus}>Update</button>
        </div>
    )
}

export default ToggleInsideTrain