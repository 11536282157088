import React from 'react';

const UpComingTable = ({ details, details_off_boarding }) => {
    return (
        <div className='card overflow-hidden rounded p-2 my-4 mx-2'>
            <h5>Chart of stations within 10 kms</h5>
            {Object.keys(details).length > 0 ? (
                <table className='border-collapse border-2 border-slate-600 w-full'>
                    <thead>
                        <tr>
                            <th className='border-2 border-slate-600'>Station</th>
                            <th className='border-2 border-slate-600'>Coach number</th>
                            <th className='border-2 border-slate-600'>Count of berths</th>
                            <th className='border-2 border-slate-600'>Berth numbers</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(details).map(([station, coaches], stationIndex) => (
                            Object.entries(coaches).map(([coach, berths], coachIndex) => {
                                const isEvenRow = (stationIndex * Object.keys(coaches).length + coachIndex) % 2 === 0;
                                const rowBackground = isEvenRow ? 'bg-white' : 'bg-gray-200';

                                return (
                                    <React.Fragment key={`${station}-${coach}`}>
                                        <tr className={rowBackground}>
                                            {coachIndex === 0 && (
                                                <td rowSpan={(Object.keys(coaches).length) * 2} className='border-2 border-slate-600'>
                                                    {station}
                                                </td>
                                            )}
                                            <td rowSpan={2} className='border-2 border-slate-600'>{coach}</td>
                                            <td className='bg-green-200 border-2 border-slate-600'>
                                                {berths.length > 0 ? berths.length : '0 onboarding'}
                                            </td>
                                            <td className='bg-green-200 border-2 border-slate-600'>
                                                {berths.length > 0 ? berths.join(', ') : '0 onboarding'}
                                            </td>
                                        </tr>
                                        <tr className={rowBackground}>
                                            <td className='bg-red-200 border-2 border-slate-600'>
                                                {details_off_boarding[station][coach].length > 0 ? details_off_boarding[station][coach].length : '0 offboarding'}
                                            </td>
                                            <td className='bg-red-200 border-2 border-slate-600'>
                                                {details_off_boarding[station][coach].length > 0 ? details_off_boarding[station][coach].join(', ') : '0 offboarding'}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })
                        ))}
                    </tbody>
                </table>
            ) : (
                "No passenger onboarding within 10 kms"
            )}
        </div>
    );
}

export default UpComingTable;
