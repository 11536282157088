import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Signup.css'
import { useState, useEffect } from 'react';
import BackgroundPattern from '../../components/BackgroundPattern/BackgroundPattern';
import {
    CModal,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CButton,
} from '@coreui/react';
import api from '../../api/api';
import Loader from '../../components/Loader/Loader';
import Select from 'react-select'
import { Link } from 'react-router-dom/dist';


const Signup = () => {
    const [passwordType, setPasswordType] = useState('password');

    const [emailInput, setEmail] = useState('');
    const [otp, setOtp] = useState();
    const [phoneInput, setPhone] = useState('');
    const [phone_otp, setPhoneOtp] = useState();
    const [firstName, setFirstName] = useState();
    const [middleName, setMiddleName] = useState();
    const [lastName, setLastName] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [userType, setUserType] = useState('railway admin');
    const [depo, setDepo] = useState()
    const [zone, setZone] = useState()

    const [displayEmail, setDisplayEmail] = useState('block');
    const [displayPhone, setDisplayPhone] = useState('block');
    const [displayEmailOtp, setDisplayEmailOtp] = useState('none');
    const [displayPhoneOtp, setDispalyPhoneOtp] = useState('none');

    const [displayEmailBtn, setDisplayEmailBtn] = useState('block');
    const [displayPhoneBtn, setDisplayPhoneBtn] = useState('block');

    const [disableEmail, setDisableEmail] = useState(false);
    const [disablePhone, setDisablePhone] = useState(true);
    const [disableFeilds, setDisableFeilds] = useState(true);
    const [disablePhonebtn, setDisablePhonebtn] = useState(true);
    const [disableSignupButton, setDisableSignupButton] = useState(false);

    const [readOnlyEmail, setReadOnlyEmail] = useState(false);
    const [readOnlyPhone, setReadOnlyPhone] = useState(false);

    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState('green');

    const [resendEmailCountdown, setResendEmailCountdown] = useState(0);
    const [resendPhoneCountdown, setResendPhoneCountdown] = useState(0);
    const [loading, setLoading] = useState(false);

    const [division, setDivision] = useState()
    const [trains, setTrains] = useState()
    const [coaches, setCoaches] = useState()
    const [TrainNos, setTrainNos] = useState()
    const [CoachNos, setCoachNos] = useState()
    const [retDepots, setRetDepots] = useState()


    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
            return;
        }
        setPasswordType('password');
    };

    var timestamp;

    const verifyEmail = () => {
        setDisableEmail(true);
        const email = emailInput.trim();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            setMessageColor('red');
            setMessage('Invalid Email');
            setVisible(true);
            setDisableEmail(false);
            return;
        }

        const registrationData = {
            email: email,
        };

        setCookie(
            'registration_data_to_validate',
            JSON.stringify(registrationData),
            30
        );

        api
            .post(
                //request-user/verify-email
                `api/users/request-user/verify-email/`, 
                {
                    email: email,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': '{{ csrf_token }}',
                    },
                }
            )
            .then((response) => {
                if (response.status === 201) {
                    if (
                        response.data.message ===
                        'Email sent successfully, Please check your Email'
                    ) {
                        setDisplayEmailOtp('block');
                        setDisplayEmail('none');
                        setMessageColor('green');
                    } else {
                        setMessageColor('red');
                    }
                    setMessage(response.data.message);
                    setVisible(true);
                } else {
                    setMessageColor('red');
                    setMessage(response.data.message);
                    setVisible(true);
                }
            })
            .catch((error) => {
                setMessageColor('red');
                setMessage(error.response.data.message);
                setVisible(true);
                setDisableEmail(false);
            });
    }; //✅

    const startResendEmailCountdown = () => {
        setResendEmailCountdown(30);
    };

    useEffect(() => {
        let countdownTimer;
    }, [resendEmailCountdown]);


    const resendEmailOtp = () => {
        const email = emailInput.trim();
        startResendEmailCountdown();

        api
            .post(
                `/user/request-user/verify-email/`,
                {
                    email: email,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': '{{ csrf_token }}',
                    },
                }
            )
            .then((response) => {
                if (response.status === 201) {
                } else {
                    setMessageColor('red');
                    setMessage('Error in resending email OTP. Please try again.');
                    setVisible(true);
                }
            })
            .catch((error) => {
                setMessageColor('red');
                setMessage('Error in resending email OTP. Please try again.');
                setVisible(true);
            });
    }; //✅

    const verifyOtp = () => {
        const registrationData = {
            otp: otp,
            email: emailInput.trim(),
        };

        setCookie(
            'registration_data_to_validate',
            JSON.stringify(registrationData),
            30
        );

        api
            .post(
                `api/users/request-user/confirm-email/`,
                {
                    otp: otp,
                    email: emailInput.trim(),
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': '{{ csrf_token }}',
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    timestamp = response.headers.get('X-Timestamp'); //There is no header with name X-Timestamp!!! Please check it from backend
                    if (timestamp !== undefined) {
                        const currentTimestamp = Math.floor(Date.now() / 1000);
                        const expirationTimestamp = parseInt(timestamp);

                        if (currentTimestamp <= expirationTimestamp) {
                            setDisplayEmailOtp('none');
                            setDisableEmail(true);
                            setDisplayEmail('block');
                            setDisplayEmailBtn('none');
                            setDisablePhone(false);
                            setDisablePhonebtn(false);
                            setReadOnlyEmail(true);
                            setMessageColor('green');
                        } else {
                            setMessageColor('red');
                        }
                    } else if (timestamp === undefined) {
                        setDisplayEmailOtp('none');
                        setDisableEmail(true);
                        setDisplayEmail('block');
                        setDisplayEmailBtn('none');
                        setDisablePhone(false);
                        setDisablePhonebtn(false);
                        setReadOnlyEmail(true);
                        setMessageColor('green');
                    } else {
                        setMessageColor('red');
                    }

                    setMessage(response.data.message);
                    setVisible(true);
                } else {
                    setMessageColor('red');
                    setMessage(response.data.message);
                    setVisible(true);
                }
            })
            .catch((error) => {
                setMessageColor('red');
                setMessage(error.response.data.message);
                setVisible(true);
            });
    }; //✅

    const verifyPhone = () => {
        setDisablePhonebtn(true);
        const phone = phoneInput.trim();
        if (/[^0-9]/.test(phone) || phone.length !== 10) {
            setMessageColor('red');
            setMessage('Invalid Phone Number');
            setVisible(true);
            setDisablePhonebtn(false);
            return;
        }

        const registrationData = {
            phone: phone,
        };

        setCookie(
            'registration_data_to_validate',
            JSON.stringify(registrationData),
            30
        );

        api
            .post(
                `api/users/request-user/verify_phone/`,
                {
                    phone: phone,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': '{{ csrf_token }}',
                    },
                }
            )
            .then((response) => {
                console.log(response, 'response');
                if (response.status === 200) {
                    if (
                        response.data.message === 'OTP Failed to send' ||
                        response.data.message ===
                        'Your Sign Up request is pending! Please wait for some time.'
                    ) {
                        setMessageColor('red');
                        setMessage(response.data.message);
                        setVisible(true);
                    } else {
                        setDispalyPhoneOtp('block');
                        setDisplayPhone('none');
                        setMessageColor('green');
                        setMessage(response.data.message);
                        setVisible(true);
                    }
                } else {
                    setMessageColor('red');
                    setMessage(response.data.message);
                    setVisible(true);
                }
            })
            .catch((error) => {
                setMessageColor('red');
                setMessage(error.response.data.message);
                setVisible(true);
                setDisablePhonebtn(false);
            });
    }; //✅

    const verifyPhoneOtp = () => {
        const registrationData = {
            phone_otp: phone_otp,
        };

        setCookie(
            'registration_data_to_validate',
            JSON.stringify(registrationData),
            30
        );
        const phone = phoneInput.trim();
        if (/[^0-9]/.test(phone) || phone.length !== 10) {
            setMessageColor('red');
            setMessage('Invalid Phone Number');
            setVisible(true);
            return;
        }

        api
            .post(
                `api/users/request-user/confirm_phone_ver/`,
                {
                    otp: phone_otp,
                    phone: phone,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': '{{ csrf_token }}',
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.message === 'OTP Verified') {
                        setDisplayPhoneBtn('none');
                        setDispalyPhoneOtp('none');
                        setDisplayPhone('block');
                        setDisablePhonebtn('none');
                        setDisableFeilds(false);
                        setReadOnlyPhone(true);
                        setMessageColor('green');
                    } else {
                        setMessageColor('red');
                    }

                    setMessage(response.data.message);
                    setVisible(true);
                } else {
                    setMessageColor('red');
                    setMessage(response.data.message);
                    setVisible(true);
                }
            })
            .catch((error) => {
                setMessageColor('red');
                setMessage(error.response.data.message);
                setVisible(true);
            });
    }; //✅
    const startResendPhoneCountdown = () => {
        setResendPhoneCountdown(30);
    };

    useEffect(() => {
        let countdownTimer;
        if (resendPhoneCountdown > 0) {
            countdownTimer = setInterval(() => {
                setResendPhoneCountdown((prevCount) => prevCount - 1);
            }, 1000);
        } else {
            clearInterval(countdownTimer);
        }

        return () => {
            clearInterval(countdownTimer);
        };

    }, [resendPhoneCountdown]);

    const resendMobileOtp = () => {
        const phone = phoneInput.trim();
        api
            .post(
                `api/users/request-user/verify_phone/`,
                {
                    phone: phone,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': '{{ csrf_token }}',
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    startResendPhoneCountdown();
                } else {
                    setMessageColor('red');
                    setMessage('Error in resending phone OTP. Please try again.');
                    setVisible(true);
                }
            })
            .catch((error) => {
                setMessageColor('red');
                setMessage('Error in resending phone OTP. Please try again.');
                setVisible(true);
            });
    }; //✅

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(division)
        console.log(trains)
        console.log(userType)
        console.log(coaches)
        const apiUrl = `api/users/request-user/`;
        if (
            password === undefined ||
            confirmPassword === undefined ||
            lastName === undefined ||
            firstName === undefined ||
            emailInput === undefined ||
            phoneInput === undefined ||
            userType === undefined ||
            division === undefined ||
            trains === undefined ||
            coaches === undefined
        ) {
            setMessageColor('red');
            setMessage('Please fill all the required fields');
            setVisible(true);
            return;
        } else {
            setDisableSignupButton(true);
            api
                .post(
                    apiUrl,
                    {
                        f_name: firstName,
                        m_name: middleName,
                        l_name: lastName,
                        password: password,
                        email: emailInput.trim(),
                        phone: phoneInput,
                        user_type: userType,
                        re_password: confirmPassword,
                        division: division,
                        trains: trains,
                        coaches: coaches,
                        zone: zone,
                        depo: depo
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': `{{ csrf_token }}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 201) {
                        if (
                            typeof response.data.message === 'object' &&
                            response.data.message !== null
                        ) {
                            setLoading(false);
                            setDisableSignupButton(false);
                            setMessageColor('red');
                            setMessage('Error try again');
                            setVisible(true);
                        } else {
                            setMessageColor('green');
                            setMessage(response.data.message);
                            setVisible(true);
                            setTimeout(() => {
                                window.location.href = '/';
                            }, 5000);
                        }
                    } else {
                        setLoading(false);
                        setDisableSignupButton(false);
                        setMessageColor('red');
                        setMessage(response.data.message);
                        setVisible(true);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setDisableSignupButton(false);
                    setMessageColor('red');
                    setMessage(error.response.data.message);
                    setVisible(true);
                });
        }
    };

    const setCookie = (name, value, minutes) => {
        const date = new Date();
        date.setTime(date.getTime() + minutes * 60 * 1000);
        const expires = 'expires=' + date.toUTCString();
        document.cookie = name + '=' + value + ';' + expires + ';path=/';
    };

    const showWarning = () => {
        setMessageColor('red');
        setMessage('Please verify your email and phone number');
        setVisible(true);
    };

    useEffect(() => {

        let is_multiple
        let search_placeholder = ""
        if (["EHK", "coach attendent"].includes(userType)) {
            // is_multiple = false
            // search_placeholder = "Select train"
            is_multiple = true
            search_placeholder = "Select all"
        }else{
            is_multiple = true
            search_placeholder = "Select all"
        }


        try{
            if (document.querySelector('#train-no')) {
                document.querySelector('#train-no').destroy();
            }
        }catch(error){
            console.log(error)
        }

        window.VirtualSelect.init({
            ele: '#train-no',
            search: true,
            options:TrainNos,
            multiple: is_multiple,
            optionSelectedText: 'Train Selected',
            optionsSelectedText: 'Trains Selected',
            allOptionsSelectedText: 'All Trains',
            searchPlaceholderText: search_placeholder,
            alwaysShowSelectedOptionsCount: true,
        });

        if (document.querySelector('#train-no')) {
            document
              .querySelector('#train-no')
              .addEventListener('change', function () {
                try{
                    setTrains(this.value.join(','))
                    if(this.value.join(',')){
                        fetchcoaches(this.value.join(','))
                    }
                }catch(e){
                    setTrains(this.value)
                    console.log(this.value)
                    fetchcoaches(this.value)
                }
                
            });
        }

    },[TrainNos,userType])

    const fetchTrains = (depo) => {
        setDepo(depo)
        api.get(
            `api/trains-of-depo/?depo=${depo}`
        ).then(response => {
            let res_trains
            let temp_trains
            res_trains = response.data.trains
            temp_trains =[]
            for(let i=0; i<res_trains.length;i++){
                temp_trains.push({value: res_trains[i], label: `${res_trains[i]}` })
            }
            setTrainNos(temp_trains)
        })
    }

    const fetchDepo = (division) => {
        setDivision(division)
        api.get(
            `api/depot-of-division/?division=${division}`
        ).then(response => {
            let res_depo
            let temp_depo
            res_depo = response.data.depo
            temp_depo =[]
            for(let i=0; i<res_depo.length;i++){
                temp_depo.push({value: res_depo[i], label: `${res_depo[i]}` })
            }
            setRetDepots(temp_depo)
        })
    }


    useEffect(() => {
        try{
            if (document.querySelector('#coach-no')) {
                document.querySelector('#coach-no').destroy();
            }
        }catch(error){
            console.log(error)
        }

        window.VirtualSelect.init({
            ele: '#coach-no',
            search: true,
            options:CoachNos,
            optionSelectedText: 'Coach Selected',
            optionsSelectedText: 'Coaches Selected',
            allOptionsSelectedText: 'All Coaches',
            searchPlaceholderText: 'Select all',
            alwaysShowSelectedOptionsCount: true,
        });

        if (document.querySelector('#coach-no')) {
            document
              .querySelector('#coach-no')
              .addEventListener('change', function () {
                setCoaches(this.value.join(','))
            });
        }

    },[CoachNos])

    const fetchcoaches = (trains) => {
        console.log(trains)
        api.get(
            `api/coaches-of-train/?trains=${trains}`
        ).then(response => {
            let res_coaches
            let temp_coaches
            res_coaches = response.data.coaches
            temp_coaches =[]
            for(let i=0; i<res_coaches.length;i++){
                temp_coaches.push({value: res_coaches[i], label: `${res_coaches[i]}` })
            }
            setCoachNos(temp_coaches)
        })
    }


    return (
        <div className="register-wrapper">
            <BackgroundPattern />

            <CModal
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="LiveDemoExampleLabel"
            >
                <CModalHeader onClose={() => setVisible(false)}>
                    <CModalTitle id="LiveDemoExampleLabel">Alert</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <p style={{ color: messageColor }}>{message}</p>
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setVisible(false)}>
                        Close
                    </CButton>
                </CModalFooter>
            </CModal>
            {loading ? (
                <Loader />
            ) : (
                <div className="container form-body">
                    <div className='shadow formHere rounded p-1'>
                        <div className="name-row">
                            <div className="col-md-4 form-input">
                                <label htmlFor="f_name">First Name *</label>
                                <input
                                    type="text"
                                    name="f_name"
                                    id="f_name"
                                    className='rounded form-control'
                                    placeholder="(only alphabets)"
                                    required
                                    onChange={(e) => setFirstName(e.target.value.trim())}
                                />
                            </div>
                            <div className="col-md-4 form-input">
                                <label htmlFor="m_name">Middle Name</label>
                                <input
                                    type="text"
                                    name="m_name"
                                    id="m_name"
                                    className='rounded form-control'
                                    placeholder="(only alphabets)"
                                    onChange={(e) => setMiddleName(e.target.value.trim())}
                                />
                            </div>
                            <div className="col-md-4 form-input">
                                <label htmlFor="l_name">Last Name *</label>
                                <input
                                    type="text"
                                    name="l_name"
                                    id="l_name"
                                    className='rounded form-control'
                                    placeholder="(only alphabets)"
                                    required
                                    onChange={(e) => setLastName(e.target.value.trim())}
                                />
                            </div>
                        </div>
                        <div
                            className="form-input"
                            style={{ width: '100%', marginBottom: '-20px' }}
                        >
                            <div id="emailFormGrp" style={{ display: displayEmail }}>
                                <div className="form-input p-0">
                                    <label htmlFor="email">Email *</label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className='rounded form-control'
                                        placeholder="(eg: example@email.com)"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        disabled={disableEmail}
                                        readOnly={readOnlyEmail}
                                    />
                                </div>
                                <button
                                    id="verifyEmailBtn"
                                    type="button"
                                    className="btn btn-primary rounded"
                                    onClick={verifyEmail}
                                    style={{
                                        borderRadius: '0',
                                        fontSize: 'smaller',
                                        padding: '1%',
                                        float: 'right',
                                        display: displayEmailBtn,
                                    }}
                                    disabled={disableEmail}
                                >
                                    Verify Email
                                </button>
                            </div>
                            <div style={{ display: displayEmailOtp }} id="emailOtpGrp">
                                <div className="form-input p-0">
                                    <label htmlFor="email_otp">OTP *</label>
                                    <input
                                        type="text"
                                        name="email_otp"
                                        id="email_otp"
                                        placeholder="Enter the OTP Code"
                                        className='rounded form-control'
                                        required
                                        onChange={(e) => setOtp(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <button
                                        id="confirmEmailOtpBtn"
                                        type="button"
                                        className="btn btn-primary rounded"
                                        style={{
                                            borderRadius: '0',
                                            fontSize: 'smaller',
                                            padding: '1%',
                                            float: 'right',
                                        }}
                                        onClick={verifyOtp}
                                    >
                                        Submit OTP
                                    </button>
                                    <span
                                        id="emailTimer"
                                        className="mx-2"
                                        style={{ padding: '1%', float: 'right' }}
                                    ></span>
                                    <button
                                        id="resendEmailOtpBtn"
                                        type="button"
                                        className="btn btn-primary mx-2 rounded "
                                        style={{
                                            display: 'displayResendEmailOtpBtn',
                                            borderRadius: '0',
                                            fontSize: 'smaller',
                                            padding: '1%',
                                            float: 'right',
                                        }}
                                        onClick={resendEmailOtp}
                                        disabled={resendEmailCountdown > 0}
                                    >
                                        {resendEmailCountdown > 0
                                            ? `Resend OTP IN (${resendEmailCountdown}s)`
                                            : 'Resend OTP'}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            className="form-input"
                            style={{ width: '100%', marginBottom: '-20px' }}
                        >
                            <div className="verify_error" id="phoneError"></div>
                            <div className="verify_success" id="phoneSuccess"></div>

                            <div id="phoneFormGrp" style={{ display: displayPhone }}>
                                <div className="form-input p-0" style={{ margin: '0%' }}>
                                    <label htmlFor="phone">Mobile *</label>
                                    <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        placeholder="(eg: 9876543210)"
                                        className='rounded form-control'
                                        maxLength="10"
                                        required
                                        disabled={disablePhone}
                                        readOnly={readOnlyPhone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                                <button
                                    id="verifyPhoneBtn"
                                    type="button"
                                    className="btn btn-primary rounded"
                                    style={{
                                        borderRadius: '0',
                                        fontSize: 'smaller',
                                        padding: '1%',
                                        float: 'right',
                                        display: displayPhoneBtn,
                                    }}
                                    disabled={disablePhonebtn}
                                    onClick={verifyPhone}
                                >
                                    Verify Mobile
                                </button>
                            </div>

                            <div style={{ display: displayPhoneOtp }} id="phoneOTPGrp">
                                <div className="form-input p-0" style={{ margin: '0%' }}>
                                    <label htmlFor="mobile_otp">OTP *</label>
                                    <input
                                        type="text"
                                        name="mobile_otp"
                                        id="mobile_otp"
                                        placeholder="Enter the OTP Code"
                                        className='rounded form-control'
                                        required
                                        onChange={(e) => setPhoneOtp(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <button
                                        id="confirmPhoneOtpBtn"
                                        type="button"
                                        className="btn btn-primary rounded"
                                        style={{
                                            borderRadius: '0',
                                            fontSize: 'smaller',
                                            padding: '1%',
                                            float: 'right',
                                        }}
                                        onClick={verifyPhoneOtp}
                                    >
                                        Submit OTP
                                    </button>
                                    <span
                                        id="phoneTimer"
                                        className="mx-2"
                                        style={{ padding: '1%', float: 'right' }}
                                    ></span>
                                    <button
                                        id="resendPhoneOtpBtn"
                                        type="button"
                                        className="btn btn-primary mx-2"
                                        style={{
                                            display: 'displayResendMobileOtpBtn',
                                            borderRadius: '0',
                                            fontSize: 'smaller',
                                            padding: '1%',
                                            float: 'right',
                                        }}
                                        onClick={resendMobileOtp}
                                        disabled={resendPhoneCountdown > 0}
                                    >
                                        {resendPhoneCountdown > 0
                                            ? `Resend OTP IN (${resendPhoneCountdown}s)`
                                            : 'Resend OTP'}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="form-input">
                            <label htmlFor="password">Password *</label>
                            <input
                                type={passwordType}
                                name="password"
                                id="password"
                                placeholder="Enter Your Password"
                                required
                                className='rounded form-control'
                                disabled={disableFeilds}
                                onChange={(e) => setPassword(e.target.value.trim())}
                            />
                        </div>
                        <div className="form-input">
                            <label htmlFor="re_password">Confirm Password *</label>
                            <input
                                type={passwordType}
                                name="re_password"
                                id="re_password"
                                placeholder="Retype Your Password"
                                className='rounded form-control'
                                required
                                disabled={disableFeilds}
                                onChange={(e) => setConfirmPassword(e.target.value.trim())}
                            />
                        </div>
                        <br />
                        <div className="show-password container mb-3">
                            <div>
                                <input
                                    className="my- 1"
                                    type="checkbox"
                                    onClick={togglePassword}
                                    disabled={disableFeilds}
                                />
                                Show Password
                            </div>
                        </div>
                        <div className="container">
                            <div className="">
                                    <label>SignUp As:</label>
                                    <select
                                        id="user_type"
                                        name="user_type"
                                        className="form-select form-control rounded"
                                        aria-label="Default select example"
                                        onChange={(e) => setUserType(e.target.value)}
                                    >
                                        <option value="" >Select user type</option>
                                        <option value="railway admin">Railway Admin</option>
                                        <option value="railway officer">Railway officer</option>
                                        <option value="write read">Write Read</option>
                                        <option value="coach attendent">Coach Attendent</option>
                                        <option value="EHK">EHK</option>
                                        <option value="s2 admin">S2 admin</option>
                                    </select>
                                
                            </div>
                        </div>

                        {/* <div className="container">
                            <div className="">
                                    <label>Select zone</label>
                                    <select
                                        id="zone"
                                        name="zone"
                                        className="form-select form-control rounded"
                                        aria-label="Default select example"
                                        onChange={(e) => setZone(e.target.value)}
                                    >
                                        <option value="" >Select zone</option>
                                        <option value="DNR">DNR</option>
                                        <option value="TKPR">TKPR</option>
                                    </select>
                            </div>
                        </div> */}

                        <div className="container">
                            <div className="">
                                    <label>Select division</label>
                                    <select
                                        id="division"
                                        name="division"
                                        className="form-select form-control rounded"
                                        aria-label="Default select example"
                                        onChange={(e) => fetchDepo(e.target.value)}
                                    >
                                        <option value="" >Select division</option>
                                        <option value="DNR">DNR</option>
                                        <option value="TKPR">TKPR</option>
                                    </select>
                            </div>
                        </div>

                        <div className="container">
                            <div className="">
                                <label>Select Depot</label>
                                <select
                                    id="depo"
                                    name="depo"
                                    className="form-select form-control rounded"
                                    aria-label="Default select example"
                                    onChange={(e) => fetchTrains(e.target.value)}
                                >
                                    <option value="" >Select depot</option>
                                    {
                                        retDepots?.map((depo) => (
                                            <option value={depo.value} key= {depo.value}>{depo.label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="container">
                        <label>Select Train Number</label>
                        <div>
                            <select placeholder='Select Train Number ' 
                                id='train-no'
                                className='multiselect mt-2 ' 
                                multiselect-search="true" 
                                multiselect-select-all="true" 
                                multiselect-max-items="1"
                                name='select'
                            >
                            </select>
                        </div>
                        </div>

                        <div className="container">
                        <label>Select Coach Number</label>
                        <div>
                            <select placeholder='Select Coach Number ' 
                                id='coach-no'
                                multiple 
                                className='multiselect mt-2 ' 
                                multiselect-search="true" 
                                multiselect-select-all="true" 
                                multiselect-max-items="1"
                                name='select'
                            >
                            </select>
                        </div>
                        </div>


                        <div className="form-input">
                            <button
                                id="signupBtn"
                                className="signup-btn my-3"
                                type="submit"
                                disabled={disableSignupButton}
                                onClick={(e) => {
                                    if (!disableFeilds) {
                                        handleSubmit(e);
                                    } else {
                                        showWarning();
                                    }
                                }}
                            >
                                Request for SignUp
                            </button>
                        </div>
                        <div className="form-input">
                            <p>
                                Already have an account?{' '}
                                <Link to="/">
                                    LogIn
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Signup;