import React, { useState, useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import api from './api/api';
import Loader from './components/Loader/Loader';

const PrivateRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem("userData");
        if (!token) {
          setIsAuthenticated(false);
          return;
        }

        const response = await api.get('/api/users/profile/', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          localStorage.removeItem('userData')
        }
      } catch (error) {
        setIsAuthenticated(false);
        localStorage.removeItem('userData')
      }
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    return <div className='bg-white'><Loader></Loader></div>;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoutes;