import React, {useState, useEffect} from "react";
import Navbar from "../../components/Navbar/Navbar";
import DashboardFilters from "../../components/Filters/DashboardFilters";
import api from "../../api/api";
import DashboardTable from "../../components/Table/DasboardTable";
import UpdateTrains from "../../components/UpdateTrains/UpdateTrains";
import Loader from "../../components/Loader/Loader";
import MsgPopup from "../../components/Popup/MsgPopup";

const Dashboard = () => {
    const [displaySidebar, setDisplaySidebar] = useState(false)
    const [trainAccessData, settrainAccessData] = useState()
    const [trainNumber, setTrainNumber] = useState()
    const [date, setDate] = useState()
    const [showLoader, setShowLoader] = useState(false)
    const [MsgModalflag, setMsgModalFlag] = useState(false)
    const [message, setMessage] = useState('')
    const [modalType, setModalType] = useState('')

    const toggleSideBar = () => {
        setDisplaySidebar(!displaySidebar)
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 991) {
                setDisplaySidebar(false);
            } else {
                setDisplaySidebar(true);
            }
        };

        handleResize();
    },[])

    const fetchTrainAccessDetails = (train_number, date) => {
        setTrainNumber(train_number)
        setDate(date)
        api.get(
            `api/users/get-all-train-access/${train_number}/${date}/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            settrainAccessData(response.data)
        })
    }

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64 = reader.result.split(',')[1];
            resolve(base64);
          };
          reader.readAsDataURL(blob);
        });
    }

    const downloadPdf = () => {
        setShowLoader(true)
        api.post(
            "pdf/train-access/",{
                "train_number":trainNumber,
                "date": date
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            const contentLength = response.headers.get('Content-Length');
            if (contentLength) {
                const contentLengthInBytes = Number(contentLength);
                if (contentLengthInBytes < 1024) {
                console.error('The PDF file is empty or corrupted.');
                console.log('The PDF file could not be downloaded. Please try again later.',);
                setMessage("The PDF file could not be downloaded. Please try again later.")
                setModalType("error")
                setMsgModalFlag(true)
                return;
                }
            }
            console.log('pdf is Downloaded successfully!!');
        
            const blob = new Blob([response.data], { type: 'application/pdf' });
            // eslint-disable-next-line no-undef
            if (typeof AndroidInterface === 'undefined') {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `coaches_assigned_${trainNumber}_${date}.pdf`;
                link.click();
                window.URL.revokeObjectURL(url);
            } else {
              blobToBase64(blob).then((base64) => {
                // eslint-disable-next-line no-undef
                AndroidInterface.base64toFile(
                  base64,
                  `coaches_assigned_${trainNumber}_${date}.pdf`,
                  'pdf'
                );
              });
            }
            setShowLoader(false)
            setMessage("pdf is Downloaded successfully")
            setModalType("success")
            setMsgModalFlag(true)
        })
        .catch((error) => {
            console.log(error)
            setShowLoader(false)
            setMessage("couldn't download pdf")
            setModalType("error")
            setMsgModalFlag(true)
        })
    }

    const mailPdf = () => {
        setShowLoader(true)
        api.post(
            "mail/train-access/",{
                "train_number":trainNumber,
                "date": date
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            console.log(response.data)
            setShowLoader(false)
            setMessage(response.data.message)
            setModalType("success")
            setMsgModalFlag(true)
        }).catch((error) => {
            console.log(error)
            setShowLoader(false)
            setMessage("couldn't send mail")
            setModalType("error")
            setMsgModalFlag(true)
        })
    }

    return(
        <div>
            <Navbar toggleSideBar={toggleSideBar}/>
            <div className='page-body' style={{ marginLeft: displaySidebar ? '210px' : 'auto' }}>
                {showLoader && <Loader></Loader>}
                <MsgPopup flag={MsgModalflag} setFlag={setMsgModalFlag} message={message} modalType={modalType} />
                <div className="shadow-xl m-8 py-4">
                    <h5 className="ml-4">View Trains and Coaches Assigned</h5>
                    <center><DashboardFilters onSubmit = {fetchTrainAccessDetails} /></center>
                    {trainAccessData && <center><DashboardTable data={trainAccessData} downloadPdf={downloadPdf} mailPdf={mailPdf}/></center>}
                </div>

                <div>
                    <UpdateTrains/>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;