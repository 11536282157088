import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ForgotPassword.css'
import { useNavigate } from 'react-router-dom';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from "@coreui/react";
import api from '../../api/api';
import BackgroundPattern from '../../components/BackgroundPattern/BackgroundPattern';
import Loader from '../../components/Loader/Loader';

const MobileLogIn = () => {
    const [phone, setPhone] = useState();
    const [otp, setOtp] = useState();
    const [error, setError] = useState();
    const [showModal, setShowModal] = useState(false);
    const [verifyingOTP, setVerifyingOTP] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showOTPModal, setShowOTPModal] = useState(false);
    const navigate = useNavigate();

    const submitHandler = (e) => {
        setShowLoader(true);
        e.preventDefault();
        document.cookie = `token=; path=/`;

        if (!phone || phone.length !== 10) {
            setShowLoader(false);
            setError('Please enter a valid phone number');
            setShowModal(true)
            setTimeout(() => {
                setShowModal(false)
            }, 2000);
            return;
        }
        api.post(
            'api/users/login-using-otp-send/',
            {
                phone_number: phone,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            if (response.status === 200) {
                setShowOTPModal(true);
            }
        }).catch((error) => {
            console.log(error)
            if (error.response.status === 404) {
                setError('Phone Number is not registered');
            } else {
                setError(error.response.data.message)
            }
            setShowModal(true);
        }).finally(() => {
            setShowLoader(false)
        })

    };
    const otpsubmitHandler = (e) => {
        setVerifyingOTP(true);
        e.preventDefault()
        if (!otp || otp.length !== 6) {
            setVerifyingOTP(false)
            setError('Please enter a valid OTP and OTP is of 6 digit');
            setShowModal(true)
            setTimeout(() => {
                setShowModal(false)
            }, 2000);
        }
        api.post('api/users/login-using-otp-verify/', {
            phone: phone,
            login_code: otp
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': '{{ csrf_token }}',
            },
        }).then(response => {
            console.log(response.data)
            if (response.data.message === "Logged in successfully") {
                localStorage.setItem('userData', JSON.stringify(response.data));
                document.cookie = `token=${response.data.access_token}; path=/`;
                localStorage.setItem('username', response.data.username);
                navigate('/TrainDetails')
            }
        }).catch((error) => {
            console.log(error);

            if (error.response) {
                setError(error.response.data.message);
                setShowModal(true);
            }
            else {
                setError(error.message);
                setShowModal(true);
            }
        }).finally(()=>{
            setVerifyingOTP(false);
        })
    }

    return (
        <div>
            <div className="loader">
                {
                    showLoader && <Loader></Loader>
                }
            </div>
            <BackgroundPattern />
            <div>
                <CModal
                    visible={showOTPModal}
                    backdrop="static"
                    aria-labelledby="ScoreNow"
                >
                    <CModalHeader onClose={() => setShowOTPModal(false)}>
                    </CModalHeader>
                    <CModalBody >
                        <h5 className=''>Enter Otp Here..</h5>
                        <form className='  w-100'>
                            <label htmlFor="otpLogIn" className='my-1'>Enter OTP Here</label>
                            <input type="number" name='otpLogIn' id='otpLogIn' placeholder='OTP...' className='form-control rounded' onChange={(e) => setOtp(e.target.value)} />
                            <div className="container  text-center">
                                <button className='btn btn-success my-2 mx-auto  w-50' onClick={otpsubmitHandler}>
                                    {
                                        verifyingOTP ?'Verifying The OTP...' : 'Submit OTP'
                                    }
                                </button>
                            </div>
                        </form>
                    </CModalBody>

                </CModal>
            </div>
            <div className="container p-5 mt-5 col-md-12 row forgotBody form-body">
                <div className='col-md-12'>
                    <h2 className="font-weight-bold mt-3">Login using OTP</h2>
                </div>
                <hr />

                <form className='forgotForm' onSubmit={submitHandler}>
                    <div className="form-group forgotForm">
                        <label htmlFor="phone_number">Phone Number</label>
                        <input
                            type="number"
                            name="phone_number"
                            placeholder="Enter your phone number"
                            className="form-control form-input rounded"
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        {error &&
                            <CModal
                                visible={showModal}
                                backdrop="static"
                                aria-labelledby="ScoreNow"
                            >
                                <CModalBody>
                                    <h5 className='text-red-600'>{error}</h5>
                                </CModalBody>
                                <CModalFooter>
                                    <CButton
                                        color="secondary"
                                        onClick={() => {
                                            setShowModal(false);
                                        }}
                                    >
                                        Ok
                                    </CButton>
                                </CModalFooter>
                            </CModal>
                        }
                    </div>
                    <br />
                    <button className="signup-btn" type="submit">
                        Send OTP
                    </button>
                </form>
            </div>
        </div>
    );
};

export default MobileLogIn;
