import React, { useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';


import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import BackgroundHeader from '../../components/BackgroundPattern/BackgroundHeader';
import api from '../../api/api';


const Profile = () => {
    library.add(faHome, faUser);
    const [profile, setProfile] = useState();
    const [displaySidebar, setDisplaySidebar] = useState(true);
    const [AddedTrainDetails, setAddedTrainDetails] = useState()
    const [role, setRole] = useState("")


    const toggleSideBar = () => {
        setDisplaySidebar(!displaySidebar);
    };

    const dateFormatter = (dateString) => {
        const dateObject = new Date(dateString);
        const formattedDateString = dateObject.toDateString();
        const dateArray = formattedDateString.split(" ");
        const date = dateArray.slice(1).join(" ");
        return date;
    }
    

    const userData=JSON.parse(localStorage.getItem("userData"));
    console.log(userData)
    const fetchInfo = useCallback(async () => {
         api
           .get(`api/users/profile/`, {
             headers: {
               'Content-Type': 'application/json',
               'X-CSRFToken': '{{ csrf_token }}',
             },
           })
           .then((response) => {
             let userData=(JSON.parse(localStorage.getItem("userData")));
             setProfile(response.data.user);
             userData.email=response.data.user.email;
             localStorage.setItem('userData',JSON.stringify(userData))
             console.log(userData);
             setRole(response.data.user.user_type)
           })
           .catch((error) => {
             console.log(error);
           });
    }, []);

    const FetchAddedTrains = () => {
        api.get(
          'api/users/profile/get_trains',
          {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': '{{ csrf_token }}',
            },
          }
        ).then(
          (response) => {
            console.log(response.data)
            setAddedTrainDetails(response.data.train_details)
          }
        ).catch((error) => {
          console.log(error)
        })
    }

    useEffect(()=>{
        fetchInfo();
        FetchAddedTrains()
    },[fetchInfo])

    return (
        <div className="page-body pt-3">
            <Navbar toggleSideBar={toggleSideBar}></Navbar>
            <div>
                <div>
                    <div className="container">
                        <div className="d-flex justify-content-end">
                            <Link
                                className="btn btn-secondary mt-[-25px]"
                                to="/EditProfile"
                            >
                                Edit Profile
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="imageUser text-center ">
                        <h1>Hello,User!</h1>
                    <span class="material-symbols-outlined" >
                        account_circle
                    </span>
                    </div>
                <section className="user-section ">
                    
                    <div className="container col-sm-12 col-md-6">
                        <div className="card  mb-4 mt-4 overflow-hidden  rounded  p-4" >
                            <div className="profile-body rounded" >
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Name </p>
                                    </div>
                                    {console.log(profile)}
                                    <div className="col-sm-9">
                                        {profile != null ? (
                                            /*<p className="text-muted mb-0">
                                                {profile.fields.first_name} {profile.fields.middle_name}{' '}
                                                {profile.fields.last_name}
                                            </p>*/
                                           
                                            <p className='text-muted'>{`${profile.first_name} ${profile.last_name}`}</p>
                                        ) : (<p>Your Name</p>)}
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Phone</p>
                                    </div>
                                    <div className="col-sm-9">
                                        {profile != null ? (
                                            <p className="text-muted mb-0">{profile.phone_number}</p>
                                        ) : (<div>0123456789</div>)}
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Username</p>
                                    </div>
                                    <div className="col-sm-9">
                                        {profile != null ? (
                                            <p className="text-muted mb-0">
                                                {profile.username}
                                            </p>
                                        ) : (<p>YourName_0123456</p>)}
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Email</p>
                                    </div>
                                    <div className="col-sm-9">
                                        {profile != null ? (
                                            <p className="mb-0" style={{ color: "#768198" }}>{profile.email}</p>
                                        ) : (<p>abc12345@gmail.com</p>)}
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Role</p>
                                    </div>
                                    <div className="col-sm-9">
                                        {profile != null ? (
                                            <p className="mb-0" style={{ color: "#768198" }}>{role}</p>
                                        ) : (<p>role</p>)}
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Division</p>
                                    </div>
                                    <div className="col-sm-9">
                                        {profile != null ? (
                                            <p className="mb-0" style={{ color: "#768198" }}>{profile.division}</p>
                                        ) : (<p>division</p>)}
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Depot</p>
                                    </div>
                                    <div className="col-sm-9">
                                        {profile != null ? (
                                            <p className="mb-0" style={{ color: "#768198" }}>{profile.depo}</p>
                                        ) : (<p>depot</p>)}
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Joined Date</p>
                                    </div>
                                    <div className="col-sm-9">
                                        {profile != null ? (
                                            <p className="text-muted mb-0">
                                                {dateFormatter(profile.created_at)}
                                            </p>
                                        ) : (<p>YYYY-MM-DD</p>)}
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <div className='card  mb-4 mt-4 overflow-hidden  rounded  p-4'>
                            {AddedTrainDetails &&(<div className='w-full'>
                                <h5>Trains Added</h5>
                                <table className='w-full'>
                                <thead>
                                    <tr>
                                    <th>Train Number</th>
                                    <th>origin date</th>
                                    <th>Coaches</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(AddedTrainDetails).map(([trainNumber, details]) => (
                                    <tr key={trainNumber}>
                                        <td>{trainNumber}</td>
                                        <td>{details.origin_date}</td>
                                        <td>
                                        {details.coach_numbers.join(', ')}
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>)}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};
export default Profile;










