import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './EditProfile.css'
import api from '../../api/api';
import { CButton, CModal, CModalBody, CModalFooter } from "@coreui/react";
import data from '../../data/trains.json'
import Select from 'react-select'
import MsgPopup from '../../components/Popup/MsgPopup';
import ToggleInsideTrain from '../../components/ProfileComps/ToggleInsideTrain';
import DeactivatePopup from '../../components/Popup/DeactivatePopup';
import ToggleNeedAlarm from '../../components/ProfileComps/ToggleNeedAlarm';
import { Element, scroller } from 'react-scroll';


function EditProfile() {

  const navigate = useNavigate();
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [fname, setFname] = useState('');
  const [mname, setMname] = useState('');
  const [lname, setLname] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState('');
  const [profile, setProfile] = useState();
  let width;
  const [date, setDate] = useState()

  const [trainNumbers , setTrainNumbers] = useState([])
  const [selectedTrain, setSelectedTrain] = useState()
  const [selectedCoach, setSelectedCoach] = useState()
  const [AddedTrainDetails, setAddedTrainDetails] = useState()
  const [MsgModalflag, setMsgModalFlag] = useState(false)
  const [message, setMessage] = useState('')
  const [modalType, setModalType] = useState('')
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const coaches = [
    {value: "A1", label: "A1"},
    {value: "A2", label: "A2"},
    {value: "B1", label: "B1"},
    {value: "B2", label: "B2"},
    {value: "B3", label: "B3"},
    {value: "B4", label: "B4"},
    {value: "B5", label: "B5"},
    {value: "B6", label: "B6"},
    {value: "H1", label: "H1"},
    {value: "H2", label: "H2"},
    {value: "M1", label: "M1"},
    {value: "M2", label: "M2"}
]

  const toggleSideBar = () => {
    setDisplaySidebar(!displaySidebar);
  };


  const fetchInfo = useCallback(async () => {
    api
      .get(`api/users/profile/`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        setProfile(response.data.user);
        setFname(response.data.user.first_name);
        setMname(response.data.user.middle_name);
        setLname(response.data.user.last_name);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const updateProfile = (e) => {
    e.preventDefault();
    if (
      (fname !== '' || fname !== undefined) &&
      (lname !== '' || lname !== undefined)
    ) {
      api
        .post(
          `api/users/profile/edit-profile/`,
          {
            fname: fname,
            mname: mname,
            lname: lname,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': '{{ csrf_token }}',
            },
          }
        )
        .then((response) => {
          if (response.data.message === 'Successfully Updated Profile') {
            setShowModal(true);
            setShowErrorMsg(response.data.message);
            let existingObject = localStorage.getItem('username');
            let usersData = JSON.parse(
              localStorage.getItem('userData')
            ).username;
            existingObject = fname + '_' + usersData.split('_').slice(-1);
            localStorage.setItem('username', existingObject);
          } else if (
            response.data.message === 'First Name can only contain alphabets!'
          ) {
            setShowModal(true);
            setShowErrorMsg(response.data.message);
          } else {
            setShowModal(true);
            setShowErrorMsg('Error Occoured');
          }
        })
        .catch((error) => {
          setShowModal(true);
          setShowErrorMsg('Check Your Name');
          console.log(error.message);
        })
        .finally(() => {
        });
    } else {
      if (fname === '' || fname === undefined) {
        setShowModal(true);
        setShowErrorMsg('Please Enter First Name');
      }
      if (lname === '' || lname === undefined) {
        setShowModal(true);
        setShowErrorMsg('Please Enter Last Name');
      }
    }
  };

  const HandleAddTrain = () => {
    api.post(
      'api/users/profile/add_trains',
      {
        train_number: selectedTrain,
        coach_numbers: selectedCoach,
        origin_date: date
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      }
    ).then(
      (response) => {
        if(response.status == 201){
          setMessage("Train added to your profile")
          setModalType("success")
          FetchAddedTrains()
        }else{
          console.log(response)
          setMessage("Couldn't add trains")
          setModalType("error")
        }
      }
    ).catch((error) => {
      console.log(error)
      setMessage("Couldn't add trains")
      setModalType("error")
    })

    setMsgModalFlag(true)
  }

  const FetchAddedTrains = () => {
    api.get(
      'api/users/profile/get_trains',
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      }
    ).then(
      (response) => {
        console.log(response.data)
        setAddedTrainDetails(response.data.train_details)
      }
    ).catch((error) => {
      console.log(error)
    })
  }

  const deleteTrains = (train_number) => {

    api.delete(`api/users/profile/delete_trains/${train_number}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': '{{ csrf_token }}',
      },
    })
    .then(
      (response) => {
        if(response.status == 204){
          setMessage("Train  deleted from your profile")
          setModalType("success")
          FetchAddedTrains()
        }else{
          setMessage("Couldn't delete train")
          setModalType("error")
        }
      }
    ).catch((error) => {
      setMessage("Couldn't add trains")
      setModalType("error")
    })

    setMsgModalFlag(true)
  }

  const deActivationHandler = (e) => {
    e.preventDefault();
    setShowDeactivateModal(true)
  };

  const deactivateAccount = () => {
    api
      .post('api/users/profile/edit-profile/deactivate-account/', {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem('userData');
          navigate('/', { replace: true });
        } else {
          console.log('Something went wrong', response);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setShowDeactivateModal(false)
      });
  };


  useEffect(() => {
    fetchInfo();
    FetchAddedTrains()

    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace('#', '');
      scroller.scrollTo(id, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }

    window.VirtualSelect.init({
      ele: '#coach-number',
      search: true,
      optionSelectedText: 'Coaches Selected',
      optionsSelectedText: 'Coaches Selected',
      allOptionsSelectedText: 'All Coaches',
      searchPlaceholderText: 'Select all',
      alwaysShowSelectedOptionsCount: true,
  });

  if (document.querySelector('#coach-number')) {
      document
        .querySelector('#coach-number')
        .addEventListener('change', function () {
          setSelectedCoach(this.value);
      });
  }

    var trains_numbers = []
    data.data.map((train) => {
        trains_numbers.push({
            value: train['train_number'],
            label: train['train_number'] 
        })
    })
    setTrainNumbers(trains_numbers)

    const handleResize = () => {
      setDisplaySidebar(window.innerWidth >= 991);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }; 
  }, [])

  return (
    <React.Fragment>


      <div className="page-body pt-3">
        <MsgPopup flag={MsgModalflag} setFlag={setMsgModalFlag} message={message} modalType={modalType} />
        <DeactivatePopup flag={showDeactivateModal} setFlag={setShowDeactivateModal} deactivateAccount={deactivateAccount} />
        <div>
          <CModal
            visible={showModal}
            backdrop="static"
            aria-labelledby="ScoreNow"
          >
            <CModalBody>
              <h5>{showErrorMsg}</h5>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="secondary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Ok
              </CButton>
            </CModalFooter>
          </CModal>
        </div>
        <div>
          <Navbar
            toggleSideBar={toggleSideBar}
          />
        </div>
        <div
          style={{
            marginLeft:
              displaySidebar === true
                ? window.innerWidth > 991
                  ? '230px'
                  : '0px'
                : '0px',
          }}
        >
          <div className="imageUser text-center ">
            <h1>Hello,User!</h1>
            <p>Update Your Profile...</p>
            <span class="material-symbols-outlined" >
              person_edit
            </span>
          </div>
          {/** Edit Profile Details */}
          <center>
            <div className="flex justify-between w-[95%] mx-auto my-8 Edit-body">
              <div className="Editprofile-body text-center w-[30%] m-0 rounded">

                <div className='row'>
                  <div className="col-md-120 d-flex justify-content-center">
                    <div className="row items-center rounded">
                      <div className="col-md-12 mb-3 ">
                        <Link to="/ChangePassword">
                          <button
                            type="button "
                            className="text-white btn btn-info fixed-width-button btn-block rounded"

                          >
                            Change Password
                          </button>
                        </Link>
                      </div>
                      <div className="col-md-12 mb-3">
                        <Link to="/ChangeMobileNumber">
                          <button
                            type="button"
                            className="text-white btn btn-info fixed-width-button btn-block rounded"

                          >
                            Change Mobile No.
                          </button>
                        </Link>
                      </div>
                      <div className="col-md-12 mb-3">
                        <Link to="/ChangeMail">
                          <button
                            type="button"
                            className="btn btn-info fixed-width-button btn-block text-white rounded"
                          >
                            Change Email
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mb-2"></div>
                <div className="d-flex justify-content-center mb-2"></div>
              </div>
              <div
                className="Edit-form rounded"
                style={{ width: '65%', margin: '0px', borderRadius: '10px' }}
              >
                <form
                  className="Editprofile-body rounded"
                  noValidate
                  style={{
                    width: '100%',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                  }}
                >

                  <div className="form" style={{ borderRadius: '10px' }}>
                    <div className="form-group row my-3  mx-3">
                      <label
                        htmlFor="fname"
                        className="col-sm-2 col-form-label"
                      >
                        {' '}
                        First Name
                      </label>
                      <div className="col-sm-10">

                        <input
                          type="text"
                          className="form-control rounded text-muted"
                          placeholder="Your First Name Here."
                          name="fname"
                          id="fname"
                          //value={fname}
                          defaultValue={fname}
                          width="100px "
                          onChange={(e) => setFname(e.target.value)}
                        />

                      </div>
                    </div>

                    <div className="form-group row my-3 mx-3">
                      <label
                        htmlFor="mname"
                        className="col-sm-2 col-form-label"
                      >
                        {' '}
                        Middle Name
                      </label>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="form-control rounded text-muted"
                          placeholder="Your Middle Name Here."
                          name="mname"
                          // value={mname}
                          id="mname"
                          defaultValue={mname}
                          onChange={(e) => setMname(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group row my-3 mx-3">
                      <label
                        htmlFor="lname"
                        className="col-sm-2 col-form-label"
                      >
                        {' '}
                        Last Name
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control rounded text-muted"
                          placeholder="Your Last Name Here."
                          name="lname"
                          // value={lname}
                          id="lname"
                          defaultValue={lname}
                          onChange={(e) => setLname(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{ marginBottom: '10px' }}
                    >
                      <button
                        className="btn btn-success rounded border col-md-4 my-1"
                        style={{
                          width: '200px',
                          borderRadius: '0px',
                          backgroundColor: '#11c15b',
                        }}
                        onClick={updateProfile}
                      >
                        Update
                      </button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </center>

         <div id={'train-access-section'}>
          <div className='bg-white shadow-xl m-8'>
              {AddedTrainDetails && <ToggleInsideTrain addedtrains={Object.keys(AddedTrainDetails)} setMsgModalFlag={setMsgModalFlag} setMessage={setMessage} setModalType={setModalType}/>}
            </div>
            <div className='bg-white shadow-xl m-8'>
              {AddedTrainDetails && <ToggleNeedAlarm setMsgModalFlag={setMsgModalFlag} setMessage={setMessage} setModalType={setModalType}/>}
            </div>

          <div className='bg-white shadow-xl m-8'>
          <div className='mt-10 p-3'>
          <h5>Add Trains</h5>
              <Select 
                className='min-w-64 mt-2 rounded' 
                options={trainNumbers} 
                placeholder='Select Train Number' 
                onChange={(e) => setSelectedTrain(e.value)}
              />

              <select placeholder='Select Coach number ' 
                id='coach-number'
                multiple 
                className='multiselect mt-2 ' 
                multiselect-search="true" 
                multiselect-select-all="true" 
                multiselect-max-items="1"
                name='select'
              >
                {coaches.map((coach) => (
                  <option value={coach.value}>{coach.label}</option>
                ))}
              </select><br/>

              <input 
                  className='dateFilter min-w-64 mt-2 rounded w-full' 
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  placeholder='select origin date'
                  onChange={(e) => setDate(e.target.value)} 
              />

              <button className='btn btn-primary mt-2' onClick={() => {HandleAddTrain()}} >Add/Update Train & Coaches</button>
            </div>

            {AddedTrainDetails &&(<div className='p-3 w-full'>
              <h5>Trains Added</h5>
              <table className='w-full'>
                <thead>
                  <tr>
                    <th>Train Number</th>
                    <th>origin_date</th>
                    <th>Coaches</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(AddedTrainDetails).map(([trainNumber, details]) => (
                    <tr key={trainNumber}>
                      <td>{trainNumber}</td>
                      <td>
                        {details.origin_date}
                      </td>
                      <td>
                        {details.coach_numbers.join(', ')}
                        <button className='btn btn-danger ml-4' onClick={() => deleteTrains(trainNumber)}>Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>)}
          </div>
         </div>

         <div
            className="row"
            style={{
              width: '95%',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '50px',
              borderRadius: '0px',
            }}
          >
            <div
              className="d-flex card shadow border col-lg-12 bg-danger text-white"
              style={{ padding: '20px' }}
              onClick={deActivationHandler}
            >
              <div className="flex flex-row items-center justify-center text-center text-xl font-semibold cursor-pointer">
                Deactivate Account{' '}
              </div>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  )
}

export default EditProfile