import React, { useEffect, useState } from 'react'
import './TrainDetails.css'
import Navbar from '../../components/Navbar/Navbar'
import Filters from '../../components/Filters/Filters'
import Table from '../../components/Table/Table'
import api from '../../api/api.js'
import Sidebar from '../../components/Sidebar/Sidebar.js'
import station_data from '../../data/stations.json'
import Loader from '../../components/Loader/Loader';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import TotalTable from './TotalTable.js'
import { useLocation} from 'react-router-dom';
import MsgPopup from '../../components/Popup/MsgPopup.js'
import TrainAccessConfirm from '../../components/HomeComps/TrainAccessConfirm.js'
import { FetchTrains } from '../../utils/FetchTrains.js'

const TrainDetails = () => {

  const [msg, setMsg] = useState('Add filters to Display Data')
  const [details, setDetails] = useState()
  const [displaySidebar, setDisplaySidebar] = useState(false)
  const [showLoader, setShowLoader] = useState(false);
  const [trainTimingInfo, setTrainTimingInfo] = useState({})
  const [stateFromMap, setStateMapFrom] = useState()
  const [msgModal, setMsgModal] = useState(false)
  const [message, setMessage] = useState("")
  const [modalType, setModalType] = useState() 
  const [isWebView, setIsWebView] = useState(true);
  const [stationCodeStr, setStationCodeStr] = useState('')
  

  const locState = useLocation()
  
  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result.split(',')[1];
        resolve(base64);
      };
      reader.readAsDataURL(blob);
    });
  }


  const fetchData = (train_number, train_name, station_code, date) => {
    setShowLoader(true);
    if (train_number === ''|| station_code === ''|| train_number === NaN) {
      setMsg('Select train number and station code')
      setShowLoader(false)
      return
    }
    console.log(train_number, train_name, station_code, date)
    const apiUrl = `api/train_number=${parseInt(train_number)}/date/${date}/station_code=${station_code}/`
    api.get(apiUrl,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      }
    ).then((response) => {
      setShowLoader(false);
      console.log(response.data)
      setDetails(response.data.trains_data)
      setShowLoader(false);

      let timingInfo = {
        "start_time": response.data.start_time,
        "end_time": response.data.end_time,
        "charting_time": response.data.charting_time,
        "charting_day": response.data.charting_day,
      }
      setTrainTimingInfo(timingInfo)

    })
  }

  const sendmailBtn = (e, date, train_no, stations) => {
    setShowLoader(true)
    e.preventDefault()
    api.post(
      "mail/onboarding_details_btn/",{
        date: date,
        train_no: train_no,
        stations: stations
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      }
    ).then((response) => {
      setShowLoader(false)
      setMessage(response.data.message)
      setModalType("success")
      setMsgModal(true)
    }).catch((error) => {
      setShowLoader(false)
      setMessage("error occured while sending mail")
      setModalType("error")
      setMsgModal(true)
    })
  }

  const toggleSideBar = () => {
    setDisplaySidebar(!displaySidebar)
  }

  const downloadAllPDFs = () => {
    if (!details || details.length === 0) {
      return;
    }

    const doc = new jsPDF();
    doc.setFontSize(12)

    let space = 0
    let first = true
    doc.text(`train_number: ${details[0].train_number} date: ${details[0].date}`, 10, 10);
    let currentY = 30; // Initialize with default start Y position

    //totalcountTable
    const totalTable = document.getElementById(`totalcountTable`);
    doc.autoTable({
      html: totalTable,
      startY: currentY + 10, // Adjust startY to leave space after text
      styles: { halign: 'center' },
    });
    doc.addPage();

    details.forEach((details, index) => {
      if (details.details.length === 0) {
        if (first) {
          doc.text(`No Data Found for ${station_data.data[details.station_code]}(${details.station_code})`, 10, currentY);
        } else {
          doc.text(`No Data Found for ${station_data.data[details.station_code]}(${details.station_code})`, 10, currentY);
        }
        currentY += 10; // Increment current Y position
      } else {
        const table = document.getElementById(`table-${details.station_code}`);


        const tableHeight = doc.autoTable.previous.finalY || 0; // Get height of previous table, or default to 0
        if (currentY + tableHeight > doc.internal.pageSize.height - 20) { // Check if adding the table will exceed page height
          doc.addPage(); // Add new page if needed
          currentY = 20; // Reset Y position
        }
        doc.text(`Station Code: ${details.station_code} Station Name: ${station_data.data[details.station_code]}`, 10, currentY);
        doc.autoTable({
          html: table,
          startY: currentY + 10, // Adjust startY to leave space after text
          styles: { halign: 'center' },
        });

        currentY = doc.autoTable.previous.finalY + 10; // Update current Y position
        first = false;
      }
    });

    doc.save(`${details[0].train_number}_${details[0].date}.pdf`);
    const blob = doc.output('blob');
    if (typeof AndroidInterface === 'undefined') {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `Station Code: ${details.station_code} Station Name: ${station_data.data[details.station_code]}.pdf`;
      link.click();
      window.URL.revokeObjectURL(url);
    } else {
      blobToBase64(blob).then((base64) => {
        // eslint-disable-next-line no-undef
        AndroidInterface.base64toFile(
          base64,
          `Station Code: ${details.station_code} Station Name: ${station_data.data[details.station_code]}.pdf`,
          'pdf'
        );
      });
    }


  };


  const pdfDownload = async (e) => {
    setShowLoader(true);
    e.preventDefault();
    let station_code=[]
    let station_code_str = ''
    details.forEach((details, index) => {
      station_code.push(details.station_code)
    })
    station_code_str = station_code.join(',')
    try {
      const response = await api.get(`/pdf/train_no=${details[0].train_number}/date/${details[0].date}/station_code=${station_code_str}/`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': '{{ csrf_token }}',
        },
      });
      setShowLoader(false);
      const contentLength = response.headers.get('Content-Length');
      if (contentLength) {
        const contentLengthInBytes = Number(contentLength);
        if (contentLengthInBytes < 1024) {
          console.error('The PDF file is empty or corrupted.');
          console.log('The PDF file could not be downloaded. Please try again later.',);
          return;
        }
      }
      console.log('pdf is Downloaded successfully!!');

      const blob = new Blob([response.data], { type: 'application/pdf' });
      // eslint-disable-next-line no-undef
      if (typeof AndroidInterface === 'undefined') {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${details[0].train_number}_${details[0].date}.pdf`;
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        blobToBase64(blob).then((base64) => {
          // eslint-disable-next-line no-undef
          AndroidInterface.base64toFile(
            base64,
            `${details[0].train_number}_${details[0].date}.pdf`,
            'pdf'
          );
        });
      }
    } catch (error) {
    }
  }; 



  useEffect(() => {

    if(locState.state){
      setStateMapFrom(locState.state)
      clearLocationState()
    }

    const now = new Date();
    const currentHour = now.getHours();
    const lastShown = localStorage.getItem('lastTrainsFetched');
    const today = now.toISOString().split('T')[0];

    if (lastShown !== today) {
      FetchTrains()
    }

    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /android|iphone|ipad|ipod|opera mini|iemobile|mobile/.test(userAgent)  || window.innerWidth < 768
    setIsWebView(!isMobile);
    console.log(isWebView)

    const handleResize = () => {
      if (window.innerWidth < 991) {
        setDisplaySidebar(false);
      } else {
        setDisplaySidebar(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const clearLocationState = () => {
    const currentUrl = window.location.pathname + window.location.search;
    window.history.replaceState({}, '', currentUrl);
  };

  useEffect(() => {
    if(details){
      let station_code=[]
      let station_code_str = ''
      details.forEach((details, index) => {
        station_code.push(details.station_code)
      })
      station_code_str = station_code.join(',')
      setStationCodeStr(station_code_str)
    }
  },[details])

  

  return (
    <div>
      <TrainAccessConfirm/>
      <div className="loader">{showLoader && <Loader></Loader>}</div>
      <Navbar toggleSideBar={toggleSideBar} />
      <MsgPopup flag={msgModal} setFlag={setMsgModal} message={message} modalType={modalType}/>
      <div className='page-body' style={{ marginLeft: displaySidebar ? '210px' : 'auto' }}>
        <center ><Filters onSubmit={fetchData} stateFromMap = {stateFromMap} /></center>
        {details ?
          <center>
            <center><button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6' onClick={(e) => { pdfDownload(e) }}>Download All Selected Stations Pdf</button></center>
            <center><button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6' onClick={(e) => { sendmailBtn(e, details[0].date, details[0].train_number, stationCodeStr) }}>Mail All Selected Stations Pdf</button></center>
            {
                  <div className='total-table-div'>
                  {details.length > 0 ? <TotalTable details={details} trainTimingInfo ={trainTimingInfo} /> : <p className='text-xl font-bold mt-12'>No Data Found for {station_data.data[details.station_code]}({details.station_code}) </p>}
                  </div>
            }
            {
              
              details.map((details) => {
                return (
                  <>
                    {details.details.length > 0 ? <Table details={details} sendmailBtn={sendmailBtn} isWebView={isWebView} /> : <p className='text-xl font-bold mt-12'>No Data Found for {station_data.data[details.station_code]}({details.station_code}) </p>}
                  </>
                )
              })
            }
          </center> :
          <center><p className='text-2xl font-bold mt-4 text-blue-500'>{msg}</p></center>
        }
      </div>
    </div>
  )
}

export default TrainDetails