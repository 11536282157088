import React, { useState, useRef, useEffect } from 'react';
import osm from './osm-providers';
import {MapContainer as Map, TileLayer, Marker, Popup} from 'react-leaflet'
import './map.css'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import CustomTooltip from './CustomTooltip';

import useGeoLocation from '../../hooks/useGeoLocation';

const markerIcon = new L.icon({
    iconUrl: require("../../assetes/map_marker.png"),
    iconSize: [35,45],
})

const markerIconStn = new L.icon({
    iconUrl: require("../../assetes/map_marker_train.png"),
    iconSize: [45,60],
})

const markerIconStn2 = new L.icon({
    iconUrl: require("../../assetes/map_marker_train_b.png"),
    iconSize: [15,20],
})

const MapComponent = ({stations,trainRoute, trainNo, date}) => {

    const location = useGeoLocation()
    const ZOOM_LEVEL = 12;
    const mapRef = useRef()
    // const [center, setCenter] = useState({lat: 25.6032109, lng: 85.1176861})

    const common_stations = stations.filter(stn => stn.tags.ref && trainRoute.includes(stn.tags.ref))
    

    return(
        location.loaded && (
            <div className='w-4/5 mt-30'>
            <Map
                center = {location.coordinates}
                // center = {center}
                zoom = {ZOOM_LEVEL}
                ref = {mapRef}
            >
                <TileLayer 
                    url={osm.maptiler.url} 
                    attribution={osm.maptiler.attribution}  
                />
                {location.loaded && !location.error && (
                    <Marker 
                        position={[location.coordinates.lat, location.coordinates.lng]} 
                        // position={[center.lat, center.lng]} 
                        icon={markerIcon}
                    >
                        <Popup>
                            <b>Your current position</b>
                        </Popup>
                    </Marker> 
                )}
                {
                    stations.map((stn, i) => ( !common_stations.includes(stn) &&
                        <Marker key={i} position={[stn.lat,stn.lon]} icon={markerIconStn2}>
                            <Popup>
                                <b>{stn.tags.name} - {stn.tags.ref}</b>
                            </Popup>
                        </Marker>
                    ))
                }
                {
                    common_stations.map((stn, i) => (
                        <Marker key={i} position={[stn.lat,stn.lon]} icon={markerIconStn}>
                            <Popup>
                                {/* <b>{stn.tags.name} - {stn.tags.ref}</b> */}
                                <CustomTooltip station ={stn.tags.ref} trainNo={trainNo} date={date}/>
                            </Popup>
                        </Marker>
                    ))
                }
            </Map>
        </div>
        )
    )
}

export default MapComponent;