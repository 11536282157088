import React, {useState, useEffect} from "react";
import api from "../../api/api";
import {
    CModal,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CButton,
} from '@coreui/react';
import Switch from "react-switch";
import Select from 'react-select';
import { Link } from "react-router-dom";

const TrainAccessConfirm = () => {
    const [AddedTrainDetails, setAddedTrainDetails] = useState()
    const [flag, setFlag] = useState(false)
    const [checked, setChecked] = useState(false)
    const [retTrain, setRetTrain] = useState("")
    const [needAlarmchecked, setNeedAlarmChecked] = useState(false)
    const [insideTrain, setInsideTrain] = useState("")
    const [addedTrainsDict, setAddedTrainsDict] = useState({})
    const [message, setMessage] = useState()

    const FetchAddedTrains = () => {
        api.get(
          'api/users/profile/get_trains',
          {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': '{{ csrf_token }}',
            },
          }
        ).then(
          (response) => {
            setAddedTrainDetails(response.data.train_details)
          }
        ).catch((error) => {
          console.log(error)
        })
    }

    const fetchInsideTrainDetails = () => {
        api.get(
            '/api/users/inside_train_status/'
        ).then((response) => {
            setChecked(response.data.inside_train)
            setRetTrain(response.data.inside_train_number)
            setInsideTrain(response.data.inside_train_number)
        })
    }

    const fetchNeedAlarmDetails = () => {
        api.get(
            '/api/users/need_alarm_status/'
        ).then((response) => {
            setNeedAlarmChecked(response.data.need_alarm)
        })
    }

    const handleCheckedInsideTrain = () => {
        setChecked(!checked)
    }

    const handleCheckedNeedAlarm = () => {
        setNeedAlarmChecked(!needAlarmchecked)
    }

    useEffect(()=>{
        FetchAddedTrains()
        fetchInsideTrainDetails()
        fetchNeedAlarmDetails()

        const now = new Date();
        const currentHour = now.getHours();
        const lastShown = localStorage.getItem('popupLastShown');
        const today = now.toISOString().split('T')[0];

        if (currentHour >= 12 && lastShown !== today) {
            setFlag(true);
            localStorage.setItem('popupLastShown', today);
        }

        
    },[])

    useEffect(() => {
        if(AddedTrainDetails){
            let trains_numbers = []
            Object.entries(AddedTrainDetails).map(([train, details]) => {
                trains_numbers.push({
                    value: train,
                    label: train
                })
            })
            setAddedTrainsDict(trains_numbers)
        }
    }, [AddedTrainDetails])

    const handleSubmit = async () => {
        try {
            let inside_train = false;
            if (checked) {
                inside_train ="true";
            }

            if(insideTrain){
                const response1 = await api.post('/api/users/toggle_inside_train/', {
                    inside_train: inside_train,
                    inside_train_number: insideTrain
                });
                console.log(response1.data);
            }
    
            let need_alarm = false;
            if (needAlarmchecked) {
                need_alarm = "true";
            }

            const response2 = await api.post('/api/users/toggle_need_alarm/', {
                need_alarm: need_alarm
            });
            console.log(response2.data);

            fetchInsideTrainDetails()
            fetchNeedAlarmDetails()
            setMessage("Update successful")
            setTimeout(() => {
                setMessage()
            }, 3000)
        } catch (error) {
            console.log(error);
        }
    };
    

    return(
        <div>
            <CModal
                visible={flag}
                onClose={() => {setFlag(false)}}
            >
                <CModalHeader
                    onClose={() => {setFlag(false)}}
                >
                    <CModalTitle >Confirm Assigned Train</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className='card mb-1 overflow-hidden  rounded  p-2'>
                        {AddedTrainDetails &&(<div className='w-full'>
                            <h5>Trains Added</h5>
                            <table className='w-full'>
                            <thead>
                                <tr>
                                <th>Train Number</th>
                                <th>origin date</th>
                                <th>Coaches</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(AddedTrainDetails).map(([trainNumber, details]) => (
                                <tr key={trainNumber}>
                                    <td>{trainNumber}</td>
                                    <td>{details.origin_date}</td>
                                    <td>
                                    {details.coach_numbers.join(', ')}
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>)}
                    </div>
                    <div className='card mt-2  rounded  p-2'>
                        <h5>Inside Train: <span className="ml-4 mt-2"><Switch checked={checked} offColor="#f00" height={20} width={40}  onChange={handleCheckedInsideTrain} /></span></h5>
                        <h5>Inside Train number: {retTrain}</h5>
                        <Select 
                            className='min-w-64 mt-2 rounded'  
                            placeholder='Select Train Number' 
                            options={addedTrainsDict}
                            onChange={(e) => setInsideTrain(e.value)}
                            defaultValue={{ value: retTrain, label: retTrain }}
                        />
                        <h5>Need Alarm: <span className="ml-4 mt-2"><Switch checked={needAlarmchecked} offColor="#f00" height={20} width={40}  onChange={handleCheckedNeedAlarm} /></span></h5>
                    </div>
                    {message && <center><h5>{message}</h5></center>}
                </CModalBody>
                <CModalFooter>
                    <CButton
                        // color="primary"
                        className="btn-success"
                        onClick={() => { setFlag(false); }}
                    >
                        Confirm
                    </CButton>

                    <Link
                        to='/EditProfile#train-access-section'
                        className="text-white no-underline"
                    >
                        <CButton
                            color="primary"
                        >
                            Edit
                        </CButton>
                    </Link>

                    <CButton
                        // color="primary"
                        className="btn-danger"
                        onClick={() => { handleSubmit() }}
                    >
                        Submit
                    </CButton>
                </CModalFooter>
            </CModal>
        </div>
    )
}

export default TrainAccessConfirm;