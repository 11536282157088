import React, {useEffect, useState} from "react";
import api from "../../api/api";
import { Link } from "react-router-dom";

const CustomTooltip = ({station, trainNo, date}) => {

    // const [details, setDetails] = useState()
    const [BerthCount, setBirthCount] = useState()

    const fetchOnboardings = (station, trainNo, date) => {
        const apiUrl = `api/train_number=${parseInt(trainNo)}/date/${date}/station_code=${station}/`
        api.get(apiUrl,
        {
            headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': '{{ csrf_token }}',
            },
        }
        ).then((response) => {
        console.log(response.data)
        // setDetails(response.data.trains_data[0])

        let TotalBerth = 0;

        response.data.trains_data[0].details.forEach((row, index) => {
            TotalBerth = TotalBerth + row.count_of_birth;
        });

        setBirthCount(TotalBerth)


        })
    }

    useEffect(() => {
        if(station && trainNo && date){
            fetchOnboardings(station,trainNo,date)
        }

    },[station, trainNo, date])

    return(
        <div className="custom-tooltip">
           <b>{station}: {BerthCount} passengers onboarding train: {trainNo} on {date}</b>
            <div className="h-6">
                <span class="material-symbols-outlined float-left ">
                    info
                </span>
                <Link
                    to='/TrainDetails'
                    state={{ train_no : trainNo , date: date, station: station}}
                >
                    click for more info
                </Link>
            </div>
        </div>
    )
}

export default CustomTooltip