import React, { useState, useEffect } from "react";
import {
    CModal,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CButton,
} from '@coreui/react';
import data from '../../data/trains.json'
import Select from 'react-select'
import { Link } from "react-router-dom";
import api from '../../api/api'

const PopupMap = ({flag,setFlag}) => {
    const [trainNumbers , setTrainNumbers] = useState([])
    // const [trainData, setTraindata] = useState({})
    const [selectedTrain, setSelectedTrain] = useState()
    const [date, setdate] = useState();
    const [autoTrainName, setAutoTrainName] = useState('train_name')

    const dateChangeHandler = (e) => {
        setdate(e.target.value);
    };

    const formatDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = yyyy + "-" + mm + "-" + dd;
        setdate(formattedToday);
    };

    useEffect(() => {
        var trains_numbers = []
        var train_data = {}
        // data.data.map((train) => {
        //     trains_numbers.push({
        //         value: train['train_number'],
        //         label: train['train_number'] 
        //     })
        //     train_data[train['train_number'].toString()] = train['train_name']
        // })

        const trains = JSON.parse(localStorage.getItem('trains'))
        trains.map((train) => {
            trains_numbers.push({
                value: train,
                label: train
            })
            train_data[train.toString()] = train['train_name']
        })


        // setTraindata(train_data)
        setTrainNumbers(trains_numbers)
        formatDate()

        fetchInsideTrainAndDate()
    },[])

    const fetchInsideTrainAndDate = () => {
        api.get(
            '/api/inside_train_and_date_for_filters/',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            console.log(response.data)
            TrainNumberChangeHandler(response.data.train_number)
            setdate(response.data.date)
        }).catch((error) => {
            console.log(error.response.data);
        })
    }

    const getTrainName = (train_no) => {
        api.get(`/api/get_train_name/?train_no=${train_no}`
            ,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            console.log(response.data)
            setAutoTrainName(response.data.train_name)
        })
    }

    const TrainNumberChangeHandler = (train_no) => {
        setSelectedTrain(train_no);
        getTrainName(train_no.toString())
    };


    return (
        <div>
            <CModal
                visible={flag}
                onClose={() => {setFlag(false)}}
            >
                <CModalHeader
                    onClose={() => {setFlag(false)}}
                >
                    <CModalTitle id="LiveDemoExampleLabel">Select Train</CModalTitle>
                </CModalHeader>
                <CModalBody>
                <Select 
                    className='min-w-64 mt-2 rounded' 
                    options={trainNumbers} 
                    placeholder='Select Train Number' 
                    value = {selectedTrain ? { value: selectedTrain, label: selectedTrain } : null}
                    onChange={(e) => TrainNumberChangeHandler(e.value)}
                />
                <input 
                    className='dateFilter min-w-64 mt-2 rounded w-full' 
                    type='text' placeholder='train_name' 
                    value={autoTrainName} readOnly 
                />
                <input 
                    className='dateFilter min-w-64 mt-2 rounded w-full' 
                    type='date' value={date} 
                    onChange={(e) => dateChangeHandler(e)} 
                />
                </CModalBody>
                <CModalFooter>
                    <Link
                        to='/mapScreen'
                        state={{ train_no: selectedTrain, date: date }}
                        className="text-white no-underline"
                    >
                        <CButton
                            color="primary"
                            onClick={() => { setFlag(false); }}
                            className="w-full" 
                        >
                            Submit
                        </CButton>
                    </Link>
                </CModalFooter>
            </CModal>

        </div>
    )
}

export default PopupMap