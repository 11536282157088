import api from '../api/api.js';

export const API_INTERVAL = 30 * 60 * 1000;

export const FetchLocation = () => {
    const now = new Date().toISOString();
    const locationDataString = localStorage.getItem('locationData');
    const locationData = locationDataString ? JSON.parse(locationDataString) : null;

    const created_at = locationData && locationData.created_at ? locationData.created_at : now;

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                const data = {
                    latitude, 
                    longitude,
                    created_at,
                    updated_at: now,
                };
                console.log(latitude, longitude)
                localStorage.setItem('locationData', JSON.stringify(data));
                console.log('Calling API...');
                sendMail(latitude,longitude)
                console.log(now);

            },
            (error) => {
                console.error('Error getting location:', error.message);
            }
        );
    }
};

const sendMail = (lat, lng) => {

    api.post(
        `mail/onboarding_details/`, {
            "lat": lat,
            "lng": lng
        },{
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': '{{ csrf_token }}',
            },
            }
    ).then((response) => {
        console.log(response.data.message)
    }).catch((error) => {
        console.log(error)
    })
}

const getRemainingTime = () => {
    const now = new Date();
    const locationDataString = localStorage.getItem('locationData');
    const locationData = locationDataString ? JSON.parse(locationDataString) : null;

    if (!locationData) {
        return 0;
    }

    const lastCallTime = new Date(locationData.updated_at);
    const timeSinceLastCall = now - lastCallTime;
    return Math.max(API_INTERVAL - timeSinceLastCall, 0);
};

const setupInterval = () => {
    return setInterval(FetchLocation, API_INTERVAL);
};

const setupTimeout = (remainingTime) => {
    return setTimeout(() => {
        FetchLocation();
        const intervalId = setupInterval();
        return () => clearInterval(intervalId);
    }, remainingTime);
};

export const scheduleApiCalls = () => {
    const locationDataString = localStorage.getItem('locationData');
    const locationData = locationDataString ? JSON.parse(locationDataString) : null;
    const remainingTime = getRemainingTime();

    if (remainingTime <= 0 || !locationData) {
        FetchLocation();
        const intervalId = setupInterval();
        return () => clearInterval(intervalId);
    } else {
        const timeoutId = setupTimeout(remainingTime);
        return () => clearTimeout(timeoutId);
    }
};
