import React, {useEffect, useState} from "react";
import data from '../../data/trains.json'
import Select from 'react-select'
import MsgPopup from '../../components/Popup/MsgPopup';
import api from "../../api/api";

const UpdateTrains = () => {

    const [trainNumbers , setTrainNumbers] = useState([])
    const [selectedTrain, setSelectedTrain] = useState()
    const [selectedCoach, setSelectedCoach] = useState()
    const [AddedTrainDetails, setAddedTrainDetails] = useState()
    const [MsgModalflag, setMsgModalFlag] = useState(false)
    const [message, setMessage] = useState('')
    const [modalType, setModalType] = useState('')
    const [allUsers, setAllUsers] = useState()
    const [selectedUser, setSelectedUser] = useState()
    const [date, setDate] = useState()
    const coaches = [
        {value: "A1", label: "A1"},
        {value: "A2", label: "A2"},
        {value: "B1", label: "B1"},
        {value: "B2", label: "B2"},
        {value: "B3", label: "B3"},
        {value: "B4", label: "B4"},
        {value: "B5", label: "B5"},
        {value: "B6", label: "B6"},
        {value: "H1", label: "H1"},
        {value: "H2", label: "H2"},
        {value: "M1", label: "M1"},
        {value: "M2", label: "M2"}
    ]

    const fetchInfo = () => {
        api.get(
            'api/users/all_users/',
            {
                headers: {
                  'Content-Type': 'application/json',
                  'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            console.log(response.data.users)
            let users = response.data.users
            let temp_users = []
            users.map((user) => {
                temp_users.push({
                    value: user.phone_number,
                    label: user.username
                })
            })
            setAllUsers(temp_users)
        }).catch((error) => {
            console.log(error)
        })
    }

    const HandleAddTrain = () => {
        api.post(
          'api/users/add_user_trains/',
          {
            train_number: selectedTrain,
            coach_numbers: selectedCoach,
            user_phone: selectedUser,
            origin_date: date
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': '{{ csrf_token }}',
            },
          }
        ).then(
          (response) => {
            if(response.status == 201){
              setMessage("Train added to your profile")
              setModalType("success")
              FetchAddedTrains(selectedUser)
            }else{
              setMessage("Couldn't add trains")
              setModalType("error")
            }
          }
        ).catch((error) => {
          setMessage("Couldn't add trains")
          setModalType("error")
        })
    
        setMsgModalFlag(true)
    }

    const handleUserChange = (e) => {
        setSelectedUser(e.value);
        FetchAddedTrains(e.value)
    }

    
    const FetchAddedTrains = (user) => {
        api.get(
        `api/users/get_user_trains/?user_phone=${user}`,
        {
            headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': '{{ csrf_token }}',
            },
        }
        ).then(
        (response) => {
            console.log(response.data)
            setAddedTrainDetails(response.data.train_details)
        }
        ).catch((error) => {
        console.log(error)
        setAddedTrainDetails()
        })
    }

    const deleteTrains = (train_number) => {

        api.delete(`api/users/delete_user_trains/${train_number}/${selectedUser}/`,
        {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': '{{ csrf_token }}',
        },
        })
        .then(
        (response) => {
            if(response.status == 204){
            setMessage("Train  deleted from your profile")
            setModalType("success")
            FetchAddedTrains(selectedUser)
            }else{
            setMessage("Couldn't delete train")
            setModalType("error")
            }
        }
        ).catch((error) => {
        setMessage("Couldn't add trains")
        setModalType("error")
        })

        setMsgModalFlag(true)
    }

    useEffect(() => {
        fetchInfo()

        window.VirtualSelect.init({
            ele: '#coach-number',
            search: true,
            optionSelectedText: 'Coaches Selected',
            optionsSelectedText: 'Coaches Selected',
            allOptionsSelectedText: 'All Coaches',
            searchPlaceholderText: 'Select all',
            alwaysShowSelectedOptionsCount: true,
        });
        
        if (document.querySelector('#coach-number')) {
            document
                .querySelector('#coach-number')
                .addEventListener('change', function () {
                setSelectedCoach(this.value);
            });
        }
        
            var trains_numbers = []
            data.data.map((train) => {
                trains_numbers.push({
                    value: train['train_number'],
                    label: train['train_number'] 
                })
            })
            setTrainNumbers(trains_numbers)
        }, [])
    
    return (
        <div>
            <div className='bg-white shadow-xl m-8'>
                <div className='mt-10 p-3'>
                    <h5>Update Trains and Coaches Assigned</h5>
                    <Select
                        className='min-w-64 mt-2 rounded'
                        options={allUsers}
                        placeholder='Select User'
                        onChange={(e) => {handleUserChange(e)}}
                    />
                    
                    <Select
                        className='min-w-64 mt-2 rounded'
                        options={trainNumbers}
                        placeholder='Select Train Number'
                        onChange={(e) => setSelectedTrain(e.value)}
                    />

                    <select placeholder='Select Coach number '
                        id='coach-number'
                        multiple
                        className='multiselect mt-2 '
                        multiselect-search="true"
                        multiselect-select-all="true"
                        multiselect-max-items="1"
                        name='select'
                    >
                        {coaches.map((coach) => (
                            <option value={coach.value}>{coach.label}</option>
                        ))}
                    </select><br />

                    <input 
                        className='dateFilter min-w-64 mt-2 rounded w-full' 
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        placeholder='select origin date'
                        onChange={(e) => setDate(e.target.value)} 
                    />

                    <button className='btn btn-primary mt-2' onClick={() => { HandleAddTrain() }} >Add/Update Train & Coaches</button>
                </div>

                {AddedTrainDetails && (<div className='p-3 w-full'>
                    <h5>Trains Added</h5>
                    <table className='w-full'>
                        <thead>
                            <tr>
                                <th>Train Number</th>
                                <th>origin_date</th>
                                <th>Coaches</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(AddedTrainDetails).map(([trainNumber, details]) => (
                                <tr key={trainNumber}>
                                    <td>{trainNumber}</td>
                                    <td>
                                        {details.origin_date}
                                    </td>
                                    <td>
                                        {details.coach_numbers.join(', ')}
                                        <button className='btn btn-danger ml-4' onClick={() => deleteTrains(trainNumber)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>)}
            </div>
        </div>
    )
}

export default UpdateTrains