import React, { useEffect, useState , useRef} from 'react';
import MsgPopup from '../../components/Popup/MsgPopup';
import Popup from 'reactjs-popup';

const AssignMultipleUsersTable = ({ coaches, caUsers, ehkUsers, coachWiseDict, ehkDict, date, train, down, hasAccess, onSubmit, fetchLastJourneyDate, removeTrainDetailsOriginDate, handleUpchange, selectedUsers , setSelectedUsers ,selectedEhk ,setSelectedEhk}) => {
    // const [selectedUsers, setSelectedUsers] = useState({});
    // const [selectedEhk, setSelectedEhk] = useState()
    const [lastJourneyDetails, setLastJourneyDetails] = useState({})
    const [MsgModalflag, setMsgModalFlag] = useState(false)
    const [message, setMessage] = useState('')
    const [modalType, setModalType] = useState('')

    const ehkOptions = ehkUsers.map(ehk => ({
        value: ehk.username,
        label: ehk.username
    }));

    const caOptions = caUsers.map(ca => ({
        value: ca.username,
        label: ca.username
    }));
   

    const transformData = (data) => {
        const userToCoaches = {};

        console.log(data)

        Object.keys(data).forEach(coach => {
            data[coach].forEach(user => {
                if (!userToCoaches[user]) {
                    userToCoaches[user] = [];
                }
                userToCoaches[user].push(coach);
            });
        });

        return userToCoaches;
    };

    const ehkUserRef = useRef(false);
    const caUserRefs = useRef({});

    useEffect(() => {
        window.VirtualSelect.init({
            ele: down ? '#ehk-user-2' : '#ehk-user',
            search: true,
            optionSelectedText: 'User Selected',
            optionsSelectedText: 'Users Selected',
            allOptionsSelectedText: 'All Users',
            searchPlaceholderText: 'Select all',
            alwaysShowSelectedOptionsCount: true,
            multiple: false,
            autoSelectFirstOption: false
        });

        if (!ehkUserRef.current) {
            const element = document.querySelector(down ? '#ehk-user-2' : '#ehk-user');
            if (element) {
                element.addEventListener('change', handleSelectChangeEhk);
                ehkUserRef.current = true;
            }
        }

        coaches.forEach(coach => {

            window.VirtualSelect.init({
                ele: down ? `#ca-user-${coach}-2`:  `#ca-user-${coach}`,
                search: true,
                optionSelectedText: 'User Selected',
                optionsSelectedText: 'Users Selected',
                allOptionsSelectedText: 'All Users',
                searchPlaceholderText: 'Select all',
                alwaysShowSelectedOptionsCount: true,
                selectedValue: '',
                multiple: false,
                autoSelectFirstOption: false
            });

            if (!caUserRefs.current[coach]) {
                const element = document.querySelector(down ? `#ca-user-${coach}-2`:  `#ca-user-${coach}`);
                if (element) {
                    element.addEventListener('change', (event) => handleSelectChange(event, coach));
                    caUserRefs.current[coach] = true;
                }
            }
        });
    }, [coaches, selectedUsers]);

    useEffect(() => {
        const initialSelectedUsers = {};
        
        if (coachWiseDict ) {
            Object.keys(coachWiseDict).forEach(coach => {
                initialSelectedUsers[coach] = coachWiseDict[coach].map(entry => entry.user);
            });
            setSelectedUsers(initialSelectedUsers);

            console.log(initialSelectedUsers)

            coaches.forEach(coach => {
                if(selectedUsers && document.querySelector(down ? `#ca-user-${coach}-2`:  `#ca-user-${coach}`)){
                    try{
                        document.querySelector(down ? `#ca-user-${coach}-2`:  `#ca-user-${coach}`).setValue('');
                    }catch(error){
                        document.querySelector(down ? `#ca-user-${coach}-2`:  `#ca-user-${coach}`).value = ''
                    }
                    if( initialSelectedUsers[coach] && initialSelectedUsers[coach].length > 0){
                        console.log("entered")
                        document.querySelector(down ? `#ca-user-${coach}-2`:  `#ca-user-${coach}`).disable()
                    }else{
                        document.querySelector(down ? `#ca-user-${coach}-2`:  `#ca-user-${coach}`).enable()
                    }
                }
            })

            setSelectedEhk(Object.keys(ehkDict))
            if(document.querySelector(down ? '#ehk-user-2' : '#ehk-user')){
                try{
                
                    document.querySelector(down ? '#ehk-user-2' : '#ehk-user').setValue('');
                }catch(error){
                    console.log(error)
                    document.querySelector(down ? '#ehk-user-2' : '#ehk-user').value = ''
                }
                if( Object.keys(ehkDict) && Object.keys(ehkDict).length > 0){
                    console.log("entered")
                    document.querySelector(down ? '#ehk-user-2' : '#ehk-user').disable()
                }else{
                    document.querySelector(down ? '#ehk-user-2' : '#ehk-user').enable()
                }
            }
        }

        
    }, [coachWiseDict]);

    const handleSelectChangeEhk = async (event) => {
        let values = event.target.value
        if(values){
            values =[values]
        }else{
            values = []
        }

        for (const value of values) {
            if (!(value in lastJourneyDetails)) {
                try {
                    const result = await fetchLastJourneyDate(value);
                    setLastJourneyDetails(prevState => ({
                        ...prevState,
                        [value]: result
                    }));
                } catch (error) {
                    console.error(`Error fetching journey date for ${value}:`, error);
                }
            }
        }
        setSelectedEhk(values)

        if(!down){
            handleUpchange(event.target.id,values)
        }
    };

    const handleSubmit = () => {
        const data = transformData(selectedUsers);

        selectedEhk.forEach((ehk) => {
            data[ehk] = coaches
        })

        onSubmit(data, train, date);
        

        // let count_more_than_one = false
        // Object.keys(data).forEach((user) => {
        //     if(data[user].length > 1){
        //         console.log(data[user])
        //         console.log("a coach attendent can be assigned to only one train")
        //         count_more_than_one = true
        //         return
        //     }
        // })

        


        // if(!count_more_than_one){
        //     onSubmit(data);
        // }else{
        //     setMessage("a coach attendent can be assigned to only one train")
        //     setModalType("error")
        //     setMsgModalFlag(true)
        // }
        
    };

    const handleSelectChange = async (event, coach) => {
        let values = event.target.value;
        if(values){
            values = [values]
        }else{
            values = []
        }
    
        for (const value of values) {
            if (!(value in lastJourneyDetails)) {
                try {
                    const result = await fetchLastJourneyDate(value);
                    setLastJourneyDetails(prevState => ({
                        ...prevState,
                        [value]: result
                    }));
                } catch (error) {
                    console.error(`Error fetching journey date for ${value}:`, error);
                }
            }
        }
    
        setSelectedUsers(prevState => ({
            ...prevState,
            [coach]: values
        }));

        if(!down){
            handleUpchange(event.target.id,values)
        }
    };

    return (
        coachWiseDict && (
            <div>
                <MsgPopup flag={MsgModalflag} setFlag={setMsgModalFlag} message={message} modalType={modalType} />
                <div style={{ maxWidth: '100vw', overflowX: 'scroll' }} className='mt-8 mx-8 mb-2 train-assign-users-table'>
                <h5>Train Number: {train} - {down ? "down":"up"}</h5>
                    <table className='w-4/5' style={{ minWidth: '500px' }}>
                        <thead>
                            <tr>
                                <th></th>
                                {hasAccess && <th>EHK/CA</th>}
                                <th>Assigned on {date}</th>
                                {hasAccess && <th>Latest journey date</th>}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>EHK</b></td>
                                {hasAccess && <td>
                                    <select
                                        placeholder='Select users'
                                        id= {down ? 'ehk-user-2' : 'ehk-user'}
                                        className='multiselect'
                                        name='ehk-user'
                                    >
                                        {ehkOptions.map(ehk => (
                                            <option 
                                                key={ehk.value} 
                                                value={ehk.value}
                                                // selected = {Object.keys(ehkDict)?.includes(ehk.value)}
                                            >
                                                {ehk.label}
                                            </option>
                                        ))}
                                    </select>
                                </td>}
                                <td>
                                    { ehkDict &&
                                        Object.keys(ehkDict).map((ehk) => (
                                            date === ehkDict[ehk] && 
                                            <p>
                                                {ehk} 
                                                {hasAccess && <button className='btn btn-danger' onClick={() => {removeTrainDetailsOriginDate(ehk, coaches, train, date)}} type='button'>Remove</button>}
                                            </p>
                                        ))
                                    }
                                </td>
                                {hasAccess && <td>
                                    {lastJourneyDetails && selectedEhk && selectedEhk.map((username) => (
                                        lastJourneyDetails[username] && 
                                        <p>
                                            <span>{lastJourneyDetails[username]["last_train_number"]} - {lastJourneyDetails[username]["last_origin_date"]} </span>
                                            <Popup
                                                className="popup-section"
                                                trigger={
                                                    <span class="material-symbols-outlined !text-base" >
                                                        info
                                                    </span>
                                            
                                                }
                                                position = {
                                                    window.innerWidth < 500 ? 'top left' : 'right'
                                                }
                                                
                                                closeOnDocumentClick
                                            >
                                                <div className='p-2 shadow rounded border bg-white'>
                                                <p>{username}</p>
                                                </div>
                                            </Popup>
                                        </p>
                                    ))}
                                </td>}
                            </tr>
                            {coaches.map((coach, index) => (
                                <tr key={index}>
                                    <td><b>{coach}</b></td>
                                    {hasAccess && <td>
                                        <select
                                            placeholder='Select users'
                                            id={ down ? `ca-user-${coach}-2`:  `ca-user-${coach}`}
                                            className='multiselect'
                                            name={`ca-user-${coach}`}
                                        >
                                            {caOptions.map(ca => (
                                                <option
                                                    key={ca.value}
                                                    value={ca.value}
                                                    // selected={selectedUsers[coach]?.includes(ca.value)}
                                                >
                                                    {ca.label}
                                                </option>
                                            ))}
                                        </select>
                                    </td>}
                                    <td>
                                        {coachWiseDict && coachWiseDict[coach] && coachWiseDict[coach].map(entry => (
                                            date === entry.origin_date && 
                                            <p key={entry.user}>{entry.user} 
                                                {hasAccess && <button className='btn btn-danger' onClick={() => {removeTrainDetailsOriginDate(entry.user, [coach], train, date)}} type='button'>Remove</button>}
                                            </p>
                                        ))}
                                    </td>
                                    {hasAccess && <td>
                                        {lastJourneyDetails &&  selectedUsers[coach] && selectedUsers[coach].map((username) => (
                                            lastJourneyDetails[username] && 
                                            <p>
                                                <span>{lastJourneyDetails[username]["last_train_number"]} - {lastJourneyDetails[username]["last_origin_date"]} </span>
                                                <Popup
                                                    className="popup-section"
                                                    trigger={
                                                        <span class="material-symbols-outlined !text-base" >
                                                            info
                                                        </span>
                                                    }
                                                    position = {
                                                        window.innerWidth < 500 ? 'top left' : 'right'
                                                    }
                                                    
                                                    closeOnDocumentClick
                                                >
                                                    <div className='p-2 shadow rounded border bg-white'>
                                                    <p>{username}</p>
                                                    </div>
                                                </Popup>
                                            </p>
                                        ))}
                                    </td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {hasAccess && <button className='btn btn-primary' onClick={handleSubmit}>Update</button>}
            </div>
        )
    );
};

export default AssignMultipleUsersTable;
