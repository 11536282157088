import React, { useEffect, useState, useRef } from 'react'
import './Filters.css'
import Select from 'react-select'
// import data from '../../data/trains.json'
import api from '../../api/api.js'
import 'virtual-select-plugin/dist/virtual-select.min.css';
import 'virtual-select-plugin/dist/virtual-select.min.js';
import station_data from '../../data/stations.json'
import useGeoLocation from '../../hooks/useGeoLocation.js'
import UpComingTable from '../Table/UpComingStTable.js'
 

const Filters = ({ onSubmit, stateFromMap }) => {
    const [date, setdate] = useState();
    const [selecttrainNumber, setSelectTrainNumber] = useState('')
    const [selecttrainName, setSelectTrainName] = useState('')
    const [selectstationCode, setSelectStationCode] = useState('')
    const [autoTrainName, setAutoTrainName] = useState('train_name')
    // const [trainData, setTraindata] = useState({})
    const [TrainNumbers, setTrainNumbers] = useState([{ value: '', label: '' }])
    const [StationCode, setStationCode] = useState([])
    const [insideTrainOnboardingDetails, setInsideTrainOnboardingDetails] = useState({})
    const selectStationRef = useRef()
    const location = useGeoLocation()

    const TrainNames = [
        {
            value: "RJPB-NDLS TEJAS RAJ EXP [RAJDHANI]",
            label: "RJPB-NDLS TEJAS RAJ EXP [RAJDHANI]",
        },
        {
            value: "SAMPOORNA KRANTI EXPRESS [SUPERFAST]",
            label: "SAMPOORNA KRANTI EXPRESS [SUPERFAST]",
        },
        { value: "South Bihar Express", label: "South Bihar Express" },
        { value: "POORVA EXPRESS", label: "POORVA EXPRESS" },
        { value: "RJPB LTT EXP", label: "RJPB LTT EXP" },
    ];

    // function hasStoppages(trainNumber) {
    //     const train = data.data.find(train => train["train_number"] == trainNumber);
    //     if (train && Array.isArray(train.stoppages) && train.stoppages.length > 0) {
    //         return train.stoppages;
    //     }
    //     return false;
    // }

    const getTrainName = (train_no) => {
        api.get(`/api/get_train_name/?train_no=${train_no}`
            ,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }
        ).then((response) => {
            console.log(response.data)
            setAutoTrainName(response.data.train_name)
            setSelectTrainName(response.data.train_name)
        })
    }


    const TrainNumberChangeHandler = (e) => {
        setSelectTrainNumber(e.value);
        // setAutoTrainName(trainData[e.value.toString()])
        getTrainName(e.value.toString())

        fetchStations(e.value)
    };

    const fetchInsideTrainOnboardingDetails = async () => {
        if (location.loaded) {
          try {
            const response = await api.post(
              '/api/onboarding_details_popup/',
              {
                lat: location.coordinates.lat,
                lng: location.coordinates.lng,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'X-CSRFToken': '{{ csrf_token }}',
                },
              }
            );
            setInsideTrainOnboardingDetails(response.data)
            return response.data;
          } catch (error) {
            console.log(error.response.data.message);
            throw error;
          }
        }
    };

    const runFunctionsSequentially = async () => {
        try {
            let insideTrainDetails = await fetchInsideTrainOnboardingDetails();
            setSelectTrainNumber(insideTrainDetails.train_number || '');
            setdate(insideTrainDetails.date || '');
            if(insideTrainDetails.train_number){
                fetchStations(insideTrainDetails.train_number)
            }
            console.log(insideTrainDetails);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };


    const fetchStations = (train_no) => {

        // let hasStoppagesResult = hasStoppages(train_no.toString());
        // console.log(train_no.toString(), hasStoppagesResult)

        // if(hasStoppagesResult){
        //     let temp_stations = []
        //     for(let i=0; i<hasStoppagesResult.length;i++){
        //         temp_stations.push({value: hasStoppagesResult[i], label: `${hasStoppagesResult[i]}-${station_data.data[hasStoppagesResult[i]]}` })
        //     }
        //     setStationCode(temp_stations)
        //     console.log("temp_stations",temp_stations)
        // }else{

        console.log("api call")
        api.get(
            `/api/train-route/?train_no=${train_no}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                },
            }).then((response) => {
                let temp_stations = []
                let res_stations = response.data.train_route
                for(let i=0; i<res_stations.length;i++){
                    temp_stations.push({value: res_stations[i], label: `${res_stations[i]}-${station_data.data[res_stations[i]]}` })
                }
                setStationCode(temp_stations)
                console.log("temp_stations",temp_stations)
            })

        // }
    }

    const dateChangeHandler = (e) => {
        setdate(e.target.value);
    };

    const stationCodeChangeHandler = (e) => {
        if(e){
            setSelectStationCode(e.value);
        }
    };

    const formatDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = yyyy + "-" + mm + "-" + dd;
        setdate(formattedToday);
    };

    const handleSubmit = (selectstationCode) => {
        console.log(selecttrainNumber, selecttrainName, selectstationCode, date)
        onSubmit(selecttrainNumber, selecttrainName, selectstationCode, date)
    }

    useEffect(() => {
        formatDate()
    }, [])

    useEffect(() => {
        if (stateFromMap) {
            setSelectTrainNumber(stateFromMap.train_no || '');
            // setSelectTrainName(trainData[stateFromMap.train_no.toString()] || '');
            // setAutoTrainName(trainData[stateFromMap.train_no.toString()] || '');

            getTrainName(stateFromMap.train_no.toString())
            if(stateFromMap.train_no){
                fetchStations(stateFromMap.train_no)
            }
        }else{
            runFunctionsSequentially()
        }
    }, [stateFromMap, location]);

    useEffect(() => {
        try{
            if (document.querySelector('#station-code')) {
                document.querySelector('#station-code').destroy();
            }
        }catch(error){
            console.log(error)
        }

        window.VirtualSelect.init({
            ele: '#station-code',
            search: true,
            options:StationCode,
            optionSelectedText: 'Station Selected',
            optionsSelectedText: 'Stations Selected',
            allOptionsSelectedText: 'All Stations',
            searchPlaceholderText: 'Select all',
            alwaysShowSelectedOptionsCount: true,
        });

        if (document.querySelector('#station-code')) {
            document
              .querySelector('#station-code')
              .addEventListener('change', function () {
                setSelectStationCode(this.value.join(','));

            });
        }

        var trains_numbers = []
        var train_data = {}
        // data.data.map((train) => {
        //     trains_numbers.push({
        //         value: train['train_number'],
        //         label: train['train_number']
        //     })
        //     train_data[train['train_number'].toString()] = train['train_name']
        // })

        const trains = JSON.parse(localStorage.getItem('trains'))
        trains.map((train) => {
            trains_numbers.push({
                value: train,
                label: train
            })
            // train_data[train.toString()] = train['train_name']
        })


        setTrainNumbers(trains_numbers)
        // setTraindata(train_data)

        if (stateFromMap) {
            document.querySelector('#station-code').setValue(stateFromMap.station);
            setdate(stateFromMap.date || '');
            setSelectStationCode(stateFromMap.station || '');
            handleSubmit(stateFromMap.station)
        }else{
            if(insideTrainOnboardingDetails && insideTrainOnboardingDetails.train_number){
                // setSelectTrainName(trainData[insideTrainOnboardingDetails.train_number.toString()] || '');
                // setAutoTrainName(trainData[insideTrainOnboardingDetails.train_number.toString()] || '');
                getTrainName(insideTrainOnboardingDetails.train_number.toString())
                document.querySelector('#station-code').toggleSelectAll(true);
                // document.querySelector('#station-code').setValue(insideTrainOnboardingDetails.stations)
                handleSubmit(document.querySelector('#station-code').value)
                // setSelectStationCode(insideTrainOnboardingDetails.stations || '');
            }
        } 

    }, [StationCode, stateFromMap, location])

    return (
        <div>
            <div className='filter-component'>
            <div className='filter-wrapper '>
                <Select 
                    className='min-w-64 mt-2 rounded' 
                    options={TrainNumbers} 
                    placeholder='Select Train Number' 
                    onChange={(e) => TrainNumberChangeHandler(e)} 
                    value={selecttrainNumber ? { value: selecttrainNumber, label: selecttrainNumber } : null}
                />
                <input 
                    className='dateFilter min-w-64 mt-2 rounded' 
                    type='text' placeholder='train_name' 
                    value={autoTrainName} readOnly 
                />
                <input 
                    className='dateFilter min-w-64 mt-2 rounded' 
                    type='date' value={date} 
                    onChange={(e) => dateChangeHandler(e)} 
                />

                <select placeholder='Select Station Code ' 
                    // ref={selectStationRef} 
                    id='station-code'
                    multiple 
                    className='multiselect mt-2 ' 
                    multiselect-search="true" 
                    multiselect-select-all="true" 
                    multiselect-max-items="1"
                    name='select'
                    value={selectstationCode}
                >
                </select>

            </div>
            <div>
                <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6' onClick={() => handleSubmit(selectstationCode)}>Submit</button>
            </div>
        </div>
        { insideTrainOnboardingDetails.details && <UpComingTable details = {insideTrainOnboardingDetails.details} details_off_boarding ={insideTrainOnboardingDetails.details_off_boarding} />}
        </div>
    );
};

export default Filters;