import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CButton, CModal, CModalBody, CModalFooter } from "@coreui/react";
import Navbar from "../../components/Navbar/Navbar";
import api from "../../api/api";

const ChangePassword = () => {

    const [displaySidebar, setDisplaySidebar] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState('');
    const [confirm_password, setCpassword] = useState("")
    const [new_password, setNpassword ] = useState("")
    const [old_password, setOpassword ] = useState("")
    const[showLoaderE,setShowLoaderE]=useState(false);
    const[showLoaderP,setShowLoaderP]=useState(false);
    const [passwordType, setPasswordType] = useState('password');

    const toggleSideBar = () => {
        setDisplaySidebar(!displaySidebar)
    }
    const togglePassword = () => {
        if (passwordType === 'password') {
          setPasswordType('text');
          return;
        }
        setPasswordType('password');
      };
    const validatePasswords=()=>{
        if(confirm_password!=="" && old_password!=="" && new_password!==""){
            if(confirm_password===new_password){
                return true;
            }
            else{
                setShowErrorMsg("The entered password and the confirmed password do not match.");
                setShowModal(true);
                return false;
            }
        }
        else{
            setShowErrorMsg("The password field cannot be left blank. Please enter a valid password.");
            setShowModal(true);
            return false;
        }
    }
    const navigate = useNavigate()

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 991) {
            setDisplaySidebar(false)
          }
          else{
            setDisplaySidebar(true)
          }
        };
    
        handleResize();
    
        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePasswordChange = (e) => {
        e.preventDefault();
        setShowLoaderP(true)
        if(validatePasswords()){
        api.post('api/users/profile/edit-profile/change_password/',{
            'old_password':old_password,
            "new_password1" : new_password,
            "new_password2" : confirm_password,
            'send_otp': 'M',
        }).then(response => {
            setShowErrorMsg(response.data.message)
            setShowModal(true);
            navigate('/PasswordChangeOtp')
            }).catch((error)=>{
            setShowErrorMsg(error.response.data.message);
            setShowModal(true);
        }).finally(()=>{
            setShowLoaderP(false);
        })
        }
        else{
            setShowLoaderP(false)
        }
    }
    const handlePasswordChangeByEmail = (e) => {
        e.preventDefault();
        setShowLoaderE(true)
        if(validatePasswords()){
        api.post('api/users/profile/edit-profile/change_password/',{
            'old_password':old_password,
            "new_password1" : new_password,
            "new_password2" : confirm_password,
            'send_otp': 'E',
        }).then(response => {
            setShowErrorMsg(response.data.message)
            setShowModal(true);
            navigate('/PasswordChangeOtp')
        }).catch((error)=>{
            setShowErrorMsg(error.response.data.message);
            setShowModal(true);
        }).finally(()=>{
            setShowLoaderE(false);
        })
        }
        else{
            setShowLoaderE(false)
        }
    }

    return(
        <div className="page-body">
           
            <div>
            <CModal
                visible={showModal}
                backdrop="static"
                aria-labelledby="ScoreNow"
            >
                <CModalBody>
                <h5>{showErrorMsg}</h5>
                </CModalBody>
                <CModalFooter>
                <CButton
                    color="secondary"
                    onClick={() => {
                    setShowModal(false);
                    }}
                >
                    Ok
                </CButton>
                </CModalFooter>
            </CModal>
            </div>
            <div>
                    <Navbar toggleSideBar={toggleSideBar}></Navbar>
            </div>
            <div  style={{ 
                marginLeft:
                displaySidebar === true
                ? window.innerWidth > 991
                ? '230px'
                : '0px'
                : '0px',
        }}>
                <section className="container-fluid  row mx-auto change-phone">
                    <div className="container  col-md-6">
                        <form className="container">
                            <div className=" mb-4  change-phone-card">
                                <div className="card-body">
                                    <center><h3>Change Your Password</h3></center>
                                    <br/>

                                    <div className="form-group">
                                        <label htmlFor="old_password" className="form-label">Old Password</label>
                                        <input type={passwordType} className="form-control rounded" name="old_password" id="old_password" placeholder="Old Password" onChange={(e) => setOpassword(e.target.value)}/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="new_password" className="form-label">New Password</label>
                                        <input type={passwordType} className="form-control rounded" name="new_password" id="new_password" placeholder="New Password" required onChange={(e) => setNpassword(e.target.value)}/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="confirm_password" className="form-label">New Password Confirmation</label>
                                        <input type={passwordType} className="form-control rounded" name="confirm_password" id="confirm_password" placeholder="Reenter New Password" required onChange={(e) => setCpassword(e.target.value)}/>
                                    </div>
                                    <div className="flex items-center my-1 text-center">
                                        <input
                                            className="h-3 w-3 mt-0.5"
                                            type="checkbox"
                                            onClick={togglePassword}
                                        />
                                        <span className="pl-1 max-sm:pl-0.5">Show Password</span>
                                    </div>
                                    <br/>

                                    <div className="text-center  mx-auto">
                                        <button className="btn btn-success col-md-4 my-3  w-100 edit-submit-btns" onClick={handlePasswordChange}>
                                            {
                                                showLoaderP ? 'Generating OTP By Mobile Number...' : 'Generate OTP By Mobile Number'
                                            }
                                        </button>
                                        <button className="btn btn-success col-md-4  w-100 edit-submit-btns" onClick={handlePasswordChangeByEmail}>
                                            {
                                                showLoaderE ? 'Generating OTP By Email...' : 'Generate OTP By Email'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ChangePassword