import React, { useState } from 'react'
import './Sidebar.css'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrainTram, faFile } from '@fortawesome/free-solid-svg-icons';
import api from '../../api/api';
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom';
import PopupMap from '../Popup/PopupMap';
import { getReadPermissions, getWritePermissions, getadminPermissions } from '../../utils/Permissions';


const Sidebar = () => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [MapPopup, setMapPopup] = useState(false)

  const WritePermissions = getWritePermissions()
  const ReadPermissions = getReadPermissions()
  const adminPermissions = getadminPermissions()

  let userType = JSON.parse(localStorage.getItem("userData")).user_type;
  let csrfToken = JSON.parse(localStorage.getItem("userData")).access_token;
  function signout() {
    return new Promise((resolve, reject) => {
      if (typeof AndroidInterface === 'undefined') {
      } else {
        // eslint-disable-next-line no-undef
        AndroidInterface.signout();
      }
      resolve();
    });
  }

  const logoutHandler = () => {
    setShowLoader(true);
    const userData = JSON.parse(localStorage.getItem("userData"))
    api
      .delete(`api/users/logout/`,
        {
          data: {
            refresh_token: userData.refresh_token
          },
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': '{{ csrf_token }}',
          },
        })
      .then(async (response) => {
        if (response.status === 200) {
          await signout();
          localStorage.removeItem('userData');
          localStorage.removeItem('phone');
          localStorage.removeItem('username');
          localStorage.removeItem('new_phone_otp');
          localStorage.removeItem('new_email_otp');
          navigate('/', { replace: true });
        } else {
          console.log('Something went wrong!', response);
          localStorage.removeItem('userData');
          localStorage.removeItem('phone');
          localStorage.removeItem('username');
          localStorage.removeItem('new_phone_otp');
          localStorage.removeItem('new_email_otp');
        }
      })
      .catch(async (error) => {
        localStorage.removeItem('userData');
          localStorage.removeItem('phone');
          localStorage.removeItem('username');
          localStorage.removeItem('new_phone_otp');
          localStorage.removeItem('new_email_otp');
        await signout();
        navigate('/', { replace: true });
      }).finally(() => {
        setShowLoader(false);
      })
  }
  library.add(faTrainTram, faFile)
  return (
    <>
      <div className="loader">
        {
          showLoader && <Loader></Loader>
        }
      </div>
      <div className='sidebar'>
        <PopupMap flag={MapPopup} setFlag = {setMapPopup}/>
        <ul>
          {(ReadPermissions.includes(userType)) && <li className='border my-1 rounded '>
            <NavLink className={(navData) => (navData.isActive ? "is-active" : 'none')} to="/TrainDetails" >
              <span class="material-symbols-outlined">
                tram
              </span>
              <span>Train Details</span>
            </NavLink>
          </li>}
          {(WritePermissions.includes(userType)) && <li className='border my-1 rounded '>
            <NavLink className={(navData) => (navData.isActive ? "is-active" : 'none')} to="/upload-data" >
              <span class="material-symbols-outlined">
                folder
              </span>
              <span>Upload Data</span>
            </NavLink>
          </li>}
          {(adminPermissions.includes(userType)) && <li className='border my-1 rounded '>
            <NavLink className={(navData) => (navData.isActive ? "is-active" : 'none')} to="/RequestedUser" >
              <span class="material-symbols-outlined">
                how_to_reg
              </span>
              <span>Approve Request</span>
            </NavLink>
          </li>}
          {(ReadPermissions.includes(userType)) && <li className='border my-1 rounded text-slate-900 '>
            <div onClick={() => setMapPopup(true)}>
              <span class="material-symbols-outlined">
                map
              </span>
              <span>See My Train</span>
            </div>
          </li>}
          {/* {(ReadPermissions.includes(userType)) && <li className='border my-1 rounded text-slate-900 '>
            <NavLink className={(navData) => (navData.isActive ? "is-active" : 'none')} to="/dashboard" >
              <span class="material-symbols-outlined">
                table
              </span>
              <span>Dashboard</span>
            </NavLink>
          </li>} */}
          <li className='border my-1 rounded '>
            <NavLink className={(navData) => (navData.isActive ? "is-active" : 'none')} to="/AssignUsers" >
              <span class="material-symbols-outlined">
                how_to_reg
              </span>
              <span>Assign EHK & CA</span>
            </NavLink>
          </li>
        </ul>
        <br />
        <ul className='bottom-profile'>
          <li className='border my-1 rounded '>
            <NavLink className={(navData) => (navData.isActive ? "is-active" : 'none')} to="/Profile" >
              <span class="material-symbols-outlined">
                person
              </span>
              <span>User Profile</span>
            </NavLink>
          </li>
          <li className='border my-1 rounded '>
            <button style={{ color: "black" }} className={(navData) => (navData.isActive ? "is-active" : 'none')} to="/Logout" onClick={logoutHandler} >
              <span class="material-symbols-outlined">
                logout
              </span>
              <span>Log Out</span>
            </button>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Sidebar