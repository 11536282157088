import React, { useEffect, useRef } from 'react';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'jszip';
import $ from 'jquery';
import DataTable from 'datatables.net';
import './Table.css'
import station_data from '../../data/stations.json'

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import api from '../../api/api';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Table = ({ details, sendmailBtn, isWebView }) => {
    const tableRef = useRef(null);
    const dataTable = useRef(null);

    useEffect(() => {
        if (details.details.length === 0) {
            if (dataTable.current) {
                dataTable.current.clear().draw();
            }
            return;
        }

        if (!dataTable.current) {
            dataTable.current = new DataTable(tableRef.current, {
                dom: 'Bfrtip',
                paging: false,
                buttons: []
            });
        }

        dataTable.current.clear();

        // dataTable.current.row.add([
        //     0,
        //     details.train_number,
        //     details.station_code,
        //     details.date
        // ])
        const newRow = dataTable.current.row.add([
            0,
            details.train_number,
            details.station_code,
            details.date,
            0,
            0,
            0,
            0
        ]).draw().node();
        $(newRow).addClass('hidden-row');

        details.details.forEach((row, index) => {
            dataTable.current.row.add([
                index + 1,
                row.coach_number,
                row.count_of_birth,
                row.off_boarding_count_of_birth,
                row.count_of_vacant_berths,
                formatBerthNumbers(row.birth_numbers),
                formatBerthNumbers(row.off_boarding_birth_numbers),
                formatBerthNumbers(row.vacant_berths)
            ]);
        });

        dataTable.current.draw();
    }, [details]);

    useEffect(() => {
        if (details.length === 0 && dataTable.current) {
            dataTable.current.destroy();
            dataTable.current = null;
        }
    }, [details]);


    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64 = reader.result.split(',')[1];
            resolve(base64);
          };
          reader.readAsDataURL(blob);
        });
    }

    function formatBerthNumbers(berthNumbers) {

        const areAllNumeric = berthNumbers.every(berth => /^-?\d+$/.test(berth));
    
        if (areAllNumeric) {
            return berthNumbers.join(', ');
        }
    
        const berthsByClass = {};
    
        berthNumbers.forEach(berth => {
            console.log(berth)
            const [number, berthClass] = berth.split('-');
            if (!berthsByClass[berthClass]) {
                berthsByClass[berthClass] = [];
            }
            berthsByClass[berthClass].push(number);
        });
    
        return Object.entries(berthsByClass).map(([berthClass, numbers]) => {
            return `{ ${berthClass}: ${numbers.join(', ')}`;
        }).join(' }   ') + ' }';
    }

    const pdfDownloadSingle = async (e) => {
        e.preventDefault();
        try {
            const response = await api.get(`/pdf/train_no=${details.train_number}/date/${details.date}/station_code=${details.station_code}/`, {
                headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': '{{ csrf_token }}',
                },
            });
            const contentLength = response.headers.get('Content-Length');
            if (contentLength) {
                const contentLengthInBytes = Number(contentLength);
                if (contentLengthInBytes < 1024) {
                console.error('The PDF file is empty or corrupted.');
                console.log('The PDF file could not be downloaded. Please try again later.',);
                return;
                }
            }
            console.log('pdf is Downloaded successfully!!');
        
            const blob = new Blob([response.data], { type: 'application/pdf' });
            // eslint-disable-next-line no-undef
            if (typeof AndroidInterface === 'undefined') {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${details.train_number}_${details.station_code}_${details.date}.pdf`;
                link.click();
                window.URL.revokeObjectURL(url);
            } else {
              blobToBase64(blob).then((base64) => {
                // eslint-disable-next-line no-undef
                AndroidInterface.base64toFile(
                  base64,
                  `${details.train_number}_${details.station_code}_${details.date}.pdf`,
                  'pdf'
                );
              });
            }
        } catch (error) {
        }
    }; 

    return (
        <div>
            <div className="table-wrapper" style={{ maxWidth: '100vw', overflowX: 'scroll' }}>
                <h5 className='mt-16'><b>Station Code:</b> {details.station_code}<br/> <b>Station name:</b> {station_data.data[details.station_code]}</h5>
                <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ' onClick={(e) => { pdfDownloadSingle(e) }}>Download pdf for {details.station_code} </button>
                <br/><br/><button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ' onClick={(e) => { sendmailBtn(e, details.date, details.train_number, details.station_code) }}>Mail pdf for {details.station_code}</button>
                <p hidden={details.details.length > 0} className='text-2xl text-red-500 font-bold'>No Data Found</p>
                <table ref={tableRef} id={`table-${details.station_code}`} hidden={details.details.length === 0} className='train-details-2' >
                    <thead>
                        <tr>
                            <th className='custom-class-column-1'>Sno</th>
                            <th className='custom-class-column-2'>Coach no</th>
                            <th className='custom-class-column-3'>Count Of onboarding Berths</th>
                            <th className='custom-class-column-3'>Count Of offboarding Berths</th>
                            <th className='custom-class-column-3'>Count Of vacant Berths</th>
                            <th>Onboarding Berth Numbers</th>
                            <th>Offboarding Berth Numbers</th>
                            <th>Vacant Berth Numbers</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    )
}

export default Table;
