import React, { useRef, useState , useEffect} from 'react';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import './UploadData.css'
import Navbar from '../../components/Navbar/Navbar';
import api from '../../api/api';

export default function UploadData() {
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    const [displaySidebar, setDisplaySidebar] = useState(false)

    const [displayMsg, setDisplayMsg] = useState(false)
    const [msg,setMsg] = useState('')

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 991) {
            setDisplaySidebar(false);
          } else {
            setDisplaySidebar(true);
          }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleSideBar = () => {
      setDisplaySidebar(!displaySidebar)
    }

    const uploadHandler = (event) => {
        const files = event.files;
        var jsonData = {};
      
        const handleFile = (file, callback) => {
          const reader = new FileReader();
      
          reader.onload = (e) => {
            try {
              const data = JSON.parse(e.target.result);
              jsonData = combineJson(jsonData,data);
              console.log(data);
              callback(); // Call the callback function after parsing the data
            } catch (error) {
              console.error('Error parsing JSON file:', error);
            }
          };
      
          reader.readAsText(file);
        };
      
        let filesProcessed = 0;
      
        const checkAllFilesProcessed = () => {
          filesProcessed++;
          if (filesProcessed === files.length) {
            console.log(jsonData)
            api.post('api/save_train_schedule/',
                {
                  jsonData: jsonData,
                  phone: JSON.parse(localStorage.getItem("userData")).phone_number
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': '{{ csrf_token }}',
                  },
                }
              ).then((response) => {
                console.log(response.data)
                setDisplayMsg(true)
                setMsg(response.data.message)
                setTimeout(() => {
                  window.location.reload()
                }, 5000);
            }).catch((e) => {
              console.log(e.message)
              setDisplayMsg(true)
              setMsg(e.message)
              setTimeout(() => {
                window.location.reload()
              }, 5000);
            })
          }
        };
      
        for (let i = 0; i < files.length; i++) {
          handleFile(files[i], checkAllFilesProcessed);
        }
    };

    function combineJson(json1, json2) { // for multiple files for future purpose if needed
        const combinedJson = { ...json1 };
        Object.keys(json2).forEach(key => {
          let newKey = key;
          let i = 1;
          while (combinedJson.hasOwnProperty(newKey)) {
            newKey = `${key}_${i}`;
            i++;
          }
          combinedJson[newKey] = json2[key];
        });
        return combinedJson;
    }
      

    
    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        console.log(e.files)
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-file mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop File Here
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div style={{marginLeft : displaySidebar ? '210px' : 'auto'}}>
            <Navbar toggleSideBar = {toggleSideBar}/>
            <center>
            <FileUpload ref={fileUploadRef} accept="application/JSON"
                onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} uploadHandler={uploadHandler} customUpload />
            </center>
            {displayMsg && <center><h5 className='mt-4 msg-container'>{msg}</h5></center>}
        </div>
    )
}
        