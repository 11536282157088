import React, {useState, useEffect} from "react";
import Switch from "react-switch";
import api from "../../api/api";

const ToggleNeedAlarm = ({setMsgModalFlag,setMessage,setModalType}) => {
    const [checked, setChecked] = useState(false)

    const handleChecked = () => {
        setChecked(!checked)
    }

    const fetchDetails = () => {
        api.get(
            '/api/users/need_alarm_status/'
        ).then((response) => {
            setChecked(response.data.need_alarm)
        })
    }

    const handleToggleStatus = () => {
        let need_alarm = false
        if(checked){
            need_alarm = "true"
        }
        api.post(
            '/api/users/toggle_need_alarm/',
            {
                need_alarm: need_alarm,
            }
        ).then((response) => {
            console.log(response.data)
            setMessage("Updated successfully")
            setModalType("success")
            setMsgModalFlag(true)
            fetchDetails()
        }).catch((error) => {
            setMessage("couldn't update")
            setModalType("error")
            setMsgModalFlag(true)
        })
    }

    useEffect(() => {
        fetchDetails()
    },[])

    return(
        <div className='mt-10 p-3'>
            <h5>Need Alarm: <span className="ml-4 mt-2"><Switch checked={checked} onChange={handleChecked} offColor="#f00" height={20} width={40} /></span></h5>
            <button className="btn btn-primary mt-4" onClick={handleToggleStatus}>Update</button>
        </div>
    )
}

export default ToggleNeedAlarm;